import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, Modal, Row, Col, FormGroup, CustomInput, UncontrolledTooltip, Label, ButtonGroup, Button, Input, ModalFooter } from 'reactstrap';
import { Icon } from '@iconify/react';
import informationOutline from '@iconify/icons-mdi/information-outline';
import Select from "react-select";
import { ESApi } from '../api/ESApi';
import { toast } from 'react-toastify';

const CreateLunchRulesModal = ({ isOpen, toggle, department }) => {

    const [lunchRules, setLunchRules] = useState(null)

    console.log({department})

    useEffect(() => {
        ESApi.get('/departments/' + department.departmentCode)
        .then(res => setLunchRules(res.data))
    }, [])

    const ifExceedsLunchOptions = [
        {
            value: 'reduce-excess',
            label: 'Reduce Excess Lunch'
        },
        {
            value: 'allow-excess',
            label: 'Allow Excess Lunch'
        }
    ]

    const punchInOrSetDeductionOptions = [
        {
            value: 'punch_in',
            label: 'Punch In'
        },
        {
            value: 'set_deduction',
            label: 'Set Deduction'
        }
    ]

    function saveLunchRules(){
        ESApi.put('/departments/lunch-rules/' + department.departmentCode, lunchRules)
        .then(res => {
            toast.success('Lunch Rules Saved')
            toggle()
        })
        .catch(err => toast.error("Error saving lunch rules"))
    }

    return (
        <Modal
        isOpen={isOpen}
        toggle={toggle}
        >
            <ModalHeader
            color='primary'
            toggle={toggle}
            >
                Create Lunch Rules
            </ModalHeader>
            {!lunchRules
            ?
            <div className='w-100'>
            <h4 className='w-100 text-center mt-3'>Loading Lunch Rules...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            :
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup className='d-flex'>
                            <CustomInput
                                type="switch"
                                name="hasLunch"
                                id="hasLunch"
                                label="Lunch Settings"
                                checked={!!lunchRules.hasLunch}
                                onChange={(e) => setLunchRules({
                                    ...lunchRules,
                                    hasLunch: e.target.checked
                                })}
                            />
                            <div id="has-lunch">
                            <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" />
                            </div>
                            <UncontrolledTooltip placement="top" target="has-lunch">
                                Enable lunch settings for department
                            </UncontrolledTooltip>
                        </FormGroup>
                    </Col>
                </Row>
                <hr className='mt-0 mb-2'/>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="punchInOrSetDeduction">Lunch Designation:</Label>
                            <div className='d-flex'>
                                <div style={{ minWidth: '200px' }}>
                                <Select
                                    id="punchInOrSetDeduction"
                                    classNamePrefix="react-select"
                                    name="punchInOrSetDeduction"
                                    isSearchable={false}
                                    onChange={(selection) => {
                                            setLunchRules({
                                                ...lunchRules,
                                                punchInOrSetDeduction: selection.value
                                            })
                                        }}
                                    value={punchInOrSetDeductionOptions.find(option => option.value === lunchRules.punchInOrSetDeduction)}
                                    options={punchInOrSetDeductionOptions}
                                >
                                </Select>
                                </div>
                                <div id="lunch-designation">
                                <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" />
                                </div>
                                <UncontrolledTooltip placement="top" target="lunch-designation">
                                    Are employees required punch in for lunch or is lunch automatically deducted
                                </UncontrolledTooltip>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <h5>Set Deduction Settings</h5>
                <Row>
                    <Col>
                        <div className='border-top mb-2' style={{ height: '1px', width: '350px' }}>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col> 
                        <FormGroup>
                        <Label>Deduct Lunch Per:</Label>   
                        <div>          
                        <ButtonGroup 
                        onClick={(e) => setLunchRules({
                            ...lunchRules,
                            setDeductionTimeframe: e.target.value
                        })} 
                        value={lunchRules.setDeductionTimeframe}
                        >
                            <Button type="radio" name="deduction-timeframe" value="day" color="primary" 
                            outline={lunchRules.setDeductionTimeframe !== 'day'}
                            >Day</Button>
                            <Button type="radio" name="deduction-timeframe" value="week" color="primary" 
                            outline={lunchRules.setDeductionTimeframe !== 'week'}
                            >Week</Button>
                        </ButtonGroup>
                        </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <FormGroup>
                            <Label>Set Deduction Amount</Label>
                            <Input 
                            type="number"
                            step={0.25}
                            value={lunchRules.setDeductionAmount}
                            onChange={(e) => {
                                setLunchRules({
                                    ...lunchRules,
                                    setDeductionAmount: e.target.value
                                })
                            }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col> 
                        <FormGroup>
                        <Label>Lunch Hrs:</Label>   
                        <div>          
                        <ButtonGroup 
                        onClick={(e) => setLunchRules({
                            ...lunchRules,
                            isLunchPaid: e.target.value === 'paid' ? true : false
                        })} 
                        value={lunchRules.isLunchPaid}
                        >
                            <Button type="radio" name="lunch-paid" value="paid" color="primary" 
                            outline={!lunchRules.isLunchPaid}
                            >Paid</Button>
                            <Button type="radio" name="lunch-paid" value="deducted" color="primary" 
                            outline={lunchRules.isLunchPaid}
                            >Deducted</Button>
                        </ButtonGroup>
                        </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <FormGroup>
                            <Label>Lunch Limit Per Day</Label>
                            <Input 
                            type="number"
                            step={0.25}
                            value={lunchRules.lunchLimitPerDay}
                            onChange={(e) => {
                                setLunchRules({
                                    ...lunchRules,
                                    lunchLimitPerDay: e.target.value
                                })
                            }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <FormGroup>
                            <Label>Minimum Required Work Hours Per Day</Label>
                            <Input 
                            type="number"
                            step={0.25}
                            value={lunchRules.minimumRequiredWorkHrsPerDay}
                            onChange={(e) => {
                                setLunchRules({
                                    ...lunchRules,
                                    minimumRequiredWorkHrsPerDay: e.target.value
                                })
                            }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ifExceedsLunch" className='d-flex'><span>Lunch Excess:</span>
                            <div id="exceeds-lunch">
                                <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" />
                                </div>
                                <UncontrolledTooltip placement="top" target="exceeds-lunch">
                                    If employee exceeds the designated lunch time
                                </UncontrolledTooltip>
                            </Label>
                            <div className='d-flex'>
                                <div style={{ minWidth: '200px' }}>
                                <Select
                                    id="ifExceedsLunch"
                                    classNamePrefix="react-select"
                                    name="ifExceedsLunch"
                                    isSearchable={false}
                                    onChange={(selection) => {
                                            setLunchRules({
                                                ...lunchRules,
                                                ifExceedsLunch: selection.value
                                            })
                                        }}
                                    value={ifExceedsLunchOptions.find(option => option.value === lunchRules.ifExceedsLunch)}
                                    options={ifExceedsLunchOptions}
                                >
                                </Select>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            }
            <ModalFooter>
                <Button 
                onClick={saveLunchRules}
                color="primary">
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default CreateLunchRulesModal;