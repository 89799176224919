import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Dashboard from '../custom-components/Dashboard';
import { UserContext } from '../contexts/UserContext';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import Employees from '../custom-components/Employees';
import Employee from '../custom-components/Employee';
import Reports from '../custom-components/Reports';
import Timesheets from '../custom-components/Timesheets';
import BatchReport from '../reports/BatchReport';
import Uploads from '../custom-components/Uploads';
import MegapayReport from '../reports/MegapayReport';
import Departments from '../custom-components/Departments';
import UploadsList from '../custom-components/UploadsList';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => {

    const { apiToken, loginUser, userLoading } = useContext(UserContext);



    return (
        <Route
            {...rest}
            render={props => {
                if (!apiToken) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                }

                // check if route is restricted by role
                // if (roles && roles.indexOf(loggedInUser.role) === -1) {
                //     // role not authorised so redirect to home page
                //     return <Redirect to={{ pathname: '/' }} />;
                // }
                if(userLoading)
                    return (
                        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                        <div>
                        <h4>Loading...</h4>
                            <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            </div>
                        </div>
                        )

                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );

}

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

const dashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-desktop',
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Dashboard,
};

const employeesRoute = {
    path: '/employees',
    name: 'Employees',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-users-alt',
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Employees,
}

const departmentsRoute = {
    path: '/departments',
    name: 'Departments',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-building',
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Departments,
}

const timesheetsRoute = {
    path: '/timesheets',
    name: 'Time Sheets',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-clock',
    badge: {
        variant: 'outline-info',
        text: 'Coming soon',
    },
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Timesheets,
}

const reportsRoute = {
    path: '/reports',
    name: 'Reports',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-file-alt',
    // badge: {
    //     variant: 'outline-info',
    //     text: 'Coming soon',
    // },
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Reports,
}

const uploadRoute = {
    path: '/upload',
    name: 'Upload Punch Ins',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-cloud-upload',
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: UploadsList,
}

const appRoutes = [
    dashboardRoute,
    employeesRoute,
    departmentsRoute,
    reportsRoute,
    uploadRoute
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

const otherRoutes = {
    path: '/detail',
    name: 'Detail',
    children: [
        {
            path: '/employees/:employeePayrollNumber',
            name: 'Settings',
            route: PrivateRoute,
            roles: ['Admin'],
            icon: 'uil-cog',
            component: Employee
        },
        {
            path: '/reports/batch-report',
            name: 'Batch Report',
            route: PrivateRoute,
            roles: ['Admin'],
            icon: 'uil-cog',
            component: BatchReport
        },
        {
            path: '/reports/megapay-report',
            name: 'Megapay Report',
            route: PrivateRoute,
            roles: ['Admin'],
            icon: 'uil-cog',
            component: MegapayReport
        },
        {
            path: '/upload/:uploadType',
            name: 'Megapay Report',
            route: PrivateRoute,
            roles: ['Admin'],
            icon: 'uil-cog',
            component: Uploads
        }
    ]
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
// All routes
const allRoutes = [rootRoute, ...appRoutes, authRoutes, otherRoutes];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
