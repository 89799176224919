import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupAddon, Label, Row, FormGroup, ButtonGroup, Col } from 'reactstrap';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import MaskedInput from "react-text-mask";
import { useState, useEffect } from "react";
import { ESApi } from "../api/ESApi";
import { toast } from "react-toastify";
import Select from "react-select";
import { add, sub } from 'date-fns'

const AddPunchInModal = ({ payrollNumber, employee, department, isOpen, toggle }) => {

    const [payrollPeriod, setPayrollPeriod] = useState(null);

    const [payrollPeriodYear, setPayrollPeriodYear] = useState(null);

    const [departments, setDepartments] = useState([]);

    const [departmentCode, setDepartmentCode] = useState(department);

    const [payrollPeriodOptions, setPayrollPeriodOptions] = useState([]);

    const [punchIn, setPunchIn] = useState({
        date: null,
        timeIn: null,
        timeInAmORPm: 'am',
        timeOut: null,
        timeOutAmORPm: 'pm',
        payrollPeriod: null,
        payrollPeriodYear: new Date().getFullYear() 
    })

    useEffect(() => {
        ESApi.get('/departments')
        .then(res => {
            setDepartments(res.data.map(department => ({label: department.name, value: department.departmentCode})))
        })
    }, [])

    function format(inputDate) {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${month}/${date}/${year}`;
    }

    useEffect(() => {
        if(isOpen && departmentCode){
            ESApi.get('/payroll/departments/' + departmentCode)
            .then(res => {
                setPayrollPeriod(res.data.currentPayrollPeriod)
                let options = [{
                    label: `${res.data.currentPayrollPeriod}: ${new Date(res.data.startDate).toLocaleDateString()}  - ${new Date(res.data.endDate).toLocaleDateString()}`,
                    startDate: new Date(res.data.startDate),
                    endDate: new Date(res.data.endDate),
                    year: new Date().getFullYear(),
                    value: res.data.currentPayrollPeriod
                }];

                let nextWeeksStartDate = add(new Date(res.data.startDate), {
                    weeks: 1
                })

                let nextWeeksEndDate = add(new Date(res.data.endDate), {
                    weeks: 1
                })
    
                options.push({
                    label: `${res.data.currentPayrollPeriod + 1 > 52 ? (res.data.currentPayrollPeriod + 1) - 52 : res.data.currentPayrollPeriod + 1}: ${new Date(nextWeeksStartDate).toLocaleDateString()}  - ${new Date(nextWeeksEndDate).toLocaleDateString()}`,
                    startDate: new Date(nextWeeksStartDate),
                    endDate: new Date(nextWeeksEndDate),
                    year: res.data.currentPayrollPeriod + 1 > 52 ? new Date().getFullYear() + 1 : new Date().getFullYear(),
                    value: res.data.currentPayrollPeriod + 1 > 52 ? (res.data.currentPayrollPeriod + 1) - 52 : res.data.currentPayrollPeriod + 1
                })
                
                for(let i = 1; i < 5; i++){

                    let startDate = sub(new Date(res.data.startDate), {
                        weeks: i
                    })

                    let endDate = sub(new Date(res.data.endDate), {
                        weeks: i
                    })

                    options.unshift({
                        label: `${res.data.currentPayrollPeriod - i < 1 ? 52 + (res.data.currentPayrollPeriod - i) : res.data.currentPayrollPeriod - i}: ${startDate.toLocaleDateString()}  - ${endDate.toLocaleDateString()}`,
                        startDate: startDate,
                        endDate: endDate,
                        year: res.data.currentPayrollPeriod - i < 1 ? new Date().getFullYear() - 1 : new Date().getFullYear(),
                        value: res.data.currentPayrollPeriod - i < 1 ? 52 + (res.data.currentPayrollPeriod - i) : res.data.currentPayrollPeriod - i
                    })
                }

                setPayrollPeriodOptions(options)
            })
        }
    }, [isOpen, departmentCode])

    const addPunchIn = () => {

        let punchInObject = {
            date: punchIn.date,
            timeIn: punchIn.timeIn + ' ' + punchIn.timeInAmORPm.toUpperCase(),
            timeOut: punchIn.timeOut + ' ' + punchIn.timeOutAmORPm.toUpperCase(),
            payrollNumber: payrollNumber,
            departmentCode: departmentCode,
            payrollPeriod: payrollPeriod,
            payrollPeriodYear: payrollPeriodOptions.find(option => option.value === payrollPeriod).year,
            punchInType: punchIn.punchInType
        }

        ESApi.post('/punch-ins', punchInObject)
        .then(res => {
            toast.success('Punch in added')
            window.location.reload()
        })
        .catch(err => toast.error('Error adding punch in'))
    }

    const punchInTypes = [
        {value: 'reg', label: 'Regular'},
        {value: 'lunch', label: 'Lunch'},
        {value: 'break', label: 'Break'}
    ]

    return(
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            >
            <ModalHeader toggle={toggle}>
                Add Punch in
            </ModalHeader>
            <ModalBody>
                <Row className="mb-2">
                    <Col>
                        <div>
                            <b>Employee:</b> {employee.firstName + ' ' + employee.lastName}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Department:</Label>
                                <Select
                                    id="departments"
                                    classNamePrefix="react-select"
                                    name="departments"
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setDepartmentCode(selection.value)
                                        }}
                                    value={!departmentCode ? null : departments.find(dep => dep.value === departmentCode)}
                                    options={departments}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Payroll Period:</Label>
                                <Select
                                    id="payroll-period"
                                    classNamePrefix="react-select"
                                    name="payroll-period"
                                    isDisabled={!departmentCode}
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setPayrollPeriodYear(selection.year)
                                            setPayrollPeriod(selection.value)
                                        }}
                                    value={payrollPeriodOptions.find(option => option.value === payrollPeriod)}
                                    options={payrollPeriodOptions}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <FormGroup>
                <InputGroup style={{ width: '350px' }}>
                    <FlatPicker
                        className='form-control rounded-left bg-white'
                        // value={date}
                        onChange={date => setPunchIn({
                            ...punchIn,
                            date: format(new Date(date))
                        })}
                        options={
                            {
                                maxDate: 'today',
                                mode: "single",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                            }
                        }
                        placeholder="Choose date..."
                    ></FlatPicker>
                    <InputGroupAddon
                        addonType="append"
                    >
                        <span className="input-group-text py-0 px-1">
                            <Icon icon={calendarRange} width="25px" />
                        </span>
                    </InputGroupAddon>
                </InputGroup>
                </FormGroup>
                </Col>
                </Row>
                <Row>
                    <Col>
                <FormGroup style={{ width: '200px' }}>
                        <Label>Time In</Label>
                        <InputGroup>
                                <MaskedInput
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="__:__"
                                className={`form-control`}
                                name="time"
                                value={punchIn.timeIn}
                                onChange={(e) => setPunchIn({
                                    ...punchIn,
                                    timeIn: e.target.value
                                })}
                                style={{ textAlign: 'left' }}
                                required
                                pattern="^(0?[1-9]|1[0-2]):[0-5][0-9]$"
                            />
                                <ButtonGroup 
                                onClick={(e) => setPunchIn({
                                    ...punchIn,
                                    timeInAmORPm: e.target.value
                                })} value={punchIn.timeInAmORPm}
                                >
                                <Button type="radio" name="am-pm" value="am" color="primary" 
                                outline={punchIn.timeInAmORPm !== 'am'}
                                >AM</Button>
                                <Button type="radio" name="am-pm" value="pm" color="primary" 
                                outline={punchIn.timeInAmORPm !== 'pm'}
                                >PM</Button>
                            </ButtonGroup>
                        </InputGroup>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                <FormGroup style={{ width: '200px' }}>
                        <Label>Time Out</Label>
                        <InputGroup>
                                <MaskedInput
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="__:__"
                                className={`form-control`}
                                name="time"
                                value={punchIn.timeOut}
                                onChange={(e) => setPunchIn({
                                    ...punchIn,
                                    timeOut: e.target.value
                                })}
                                style={{ textAlign: 'left' }}
                                required
                                pattern="^(0?[1-9]|1[0-2]):[0-5][0-9]$"
                            />
                                <ButtonGroup 
                                onClick={(e) => setPunchIn({
                                    ...punchIn,
                                    timeOutAmORPm: e.target.value
                                })} value={punchIn.timeOutAmORPm}
                                >
                                <Button type="radio" name="am-pm" value="am" color="primary" 
                                outline={punchIn.timeOutAmORPm !== 'am'}
                                >AM</Button>
                                <Button type="radio" name="am-pm" value="pm" color="primary" 
                                outline={punchIn.timeOutAmORPm !== 'pm'}
                                >PM</Button>
                            </ButtonGroup>
                        </InputGroup>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Type:</Label>
                                <Select
                                    id="type"
                                    classNamePrefix="react-select"
                                    name="type"
                                    onChange={(selection) => {
                                        setPunchIn({
                                            ...punchIn,
                                            punchInType: selection.value
                                        })
                                        }}
                                    value={punchInTypes.find(type => type.value === punchIn.punchInType)}
                                    options={punchInTypes}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={addPunchIn}>
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddPunchInModal;