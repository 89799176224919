import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupAddon, Label, Row, FormGroup, ButtonGroup, Col } from 'reactstrap';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import MaskedInput from "react-text-mask";
import { useState, useEffect } from "react";
import { ESApi } from "../api/ESApi";
import { toast } from "react-toastify";
import Select from "react-select";
import { sub } from 'date-fns'
import date from "availity-reactstrap-validation/lib/AvValidator/date";

const EditPunchInModal = ({ employee, toggle, punchIn }) => {

    const [departments, setDepartments] = useState([]);

    const [departmentCode, setDepartmentCode] = useState(null);

    const [payrollPeriodOptions, setPayrollPeriodOptions] = useState([]);

    const [tempPunchIn, setTempPunchIn] = useState(null)

    const query = new URLSearchParams();

    function reFormatFromEuToUs(inputDate){
        let splitDate = inputDate.split('-');
        let year = splitDate[0];
        let month = splitDate[1];
        let day = splitDate[2];

        return `${month}/${day}/${year}`
    }

    useEffect(() => {
        if(punchIn){

            setTempPunchIn({
                departmentCode: punchIn.departmentCode,
                punchInType: punchIn.punchInType,
                date: reFormatFromEuToUs(punchIn.date),
                timeIn: new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).slice(0, -3),
                timeInAmORPm: new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).slice(-2).toLocaleLowerCase(),
                timeOut: punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).slice(0, -3) : null,
                timeOutAmORPm: punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}).slice(-2).toLocaleLowerCase() : 'pm',
                payrollPeriod: punchIn.payrollPeriod || null,
                payrollPeriodYear: punchIn.payrollPeriodYear || null,
            })

            setDepartmentCode(punchIn.departmentCode)
        }
    }, [punchIn])

    useEffect(() => {
        ESApi.get('/departments')
        .then(res => {
            setDepartments(res.data.map(department => ({label: department.name, value: department.departmentCode})))
        })
    }, [])

    function format(inputDate) {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${month}/${date}/${year}`;
    }

    useEffect(() => {

        if(punchIn && departmentCode){
            query.set('payrollPeriod', punchIn.payrollPeriod)
            query.set('payrollPeriodYear', punchIn.payrollPeriodYear)
            ESApi.get(`/payroll/departments/${departmentCode}?${query.toString()}`)
            .then(res => {
                let options = [{
                    label: `${res.data.currentPayrollPeriod}: ${new Date(res.data.startDate).toLocaleDateString()}  - ${new Date(res.data.endDate).toLocaleDateString()}`,
                    startDate: new Date(res.data.startDate),
                    endDate: new Date(res.data.endDate),
                    year: new Date(res.data.currentPayrollPeriodDate).getFullYear(),
                    value: res.data.currentPayrollPeriod
                }];
                
                for(let i = 1; i < 5; i++){

                    let startDate = sub(new Date(res.data.startDate), {
                        weeks: i
                    })

                    let endDate = sub(new Date(res.data.endDate), {
                        weeks: i
                    })

                    options.unshift({
                        label: `${res.data.currentPayrollPeriod - i < 1 ? 52 + (res.data.currentPayrollPeriod - i) : res.data.currentPayrollPeriod - i}: ${startDate.toLocaleDateString()}  - ${endDate.toLocaleDateString()}`,
                        startDate: startDate,
                        endDate: endDate,
                        year: res.data.currentPayrollPeriod - i < 1 ? new Date(res.data.currentPayrollPeriodDate).getFullYear() - 1 : new Date(res.data.currentPayrollPeriodDate).getFullYear(),
                        value: res.data.currentPayrollPeriod - i < 1 ? 52 + (res.data.currentPayrollPeriod - i) : res.data.currentPayrollPeriod - i
                    })
                }

                setPayrollPeriodOptions(options)
            })
        }
    }, [punchIn, departmentCode])

    const editPunchIn = () => {

        let punchInObject = {
            punchInType: tempPunchIn.punchInType,
            date: tempPunchIn.date,
            timeIn: tempPunchIn.timeIn + ' ' + tempPunchIn.timeInAmORPm.toUpperCase(),
            timeOut: tempPunchIn.timeOut + ' ' + tempPunchIn.timeOutAmORPm.toUpperCase(),
            payrollNumber: punchIn.payrollNumber,
            departmentCode: departmentCode,
            payrollPeriod: tempPunchIn.payrollPeriod,
            payrollPeriodYear: tempPunchIn.payrollPeriodYear,
            associativeId: punchIn.associativeId,
            generatedId: punchIn.generatedId
        }

        ESApi.put('/punch-ins/' + punchIn.id, punchInObject)
        .then(res => {
            toast.success('Punch in added')
            window.location.reload()
        })
        .catch(err => toast.error('Error adding punch in'))
    }

    if(!tempPunchIn){
        return ''
    }

    const punchInTypes = [
        {value: 'reg', label: 'Regular'},
        {value: 'lunch', label: 'Lunch'},
        {value: 'break', label: 'Break'}
    ]

    return(
        <Modal
            isOpen={!!punchIn}
            toggle={toggle}
            >
            <ModalHeader toggle={toggle}>
                Edit Punch in
            </ModalHeader>
            <ModalBody>
                <Row className="mb-2">
                    <Col>
                        <div>
                            <b>Employee:</b> {employee?.firstName + ' ' + employee?.lastName}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Department:</Label>
                                <Select
                                    id="departments"
                                    classNamePrefix="react-select"
                                    name="departments"
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setDepartmentCode(selection.value)
                                        }}
                                    value={!departmentCode ? null : departments.find(dep => dep.value === departmentCode)}
                                    options={departments}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Payroll Period:</Label>
                                <Select
                                    id="payroll-period"
                                    classNamePrefix="react-select"
                                    name="payroll-period"
                                    isDisabled={!departmentCode}
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setTempPunchIn({
                                                ...tempPunchIn,
                                                payrollPeriod: selection.value,
                                                payrollPeriodYear: selection.year
                                            })
                                        }}
                                    value={payrollPeriodOptions.find(option => option.value === tempPunchIn.payrollPeriod)}
                                    options={payrollPeriodOptions}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <FormGroup>
                <InputGroup style={{ width: '350px' }}>
                    <FlatPicker
                        className='form-control rounded-left bg-white'
                        value={new Date(tempPunchIn.date)}
                        onChange={date => {
                            setTempPunchIn({
                            ...tempPunchIn,
                            date: format(new Date(date))
                        })}}
                        options={
                            {
                                maxDate: 'today',
                                mode: "single",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                            }
                        }
                        placeholder="Choose date..."
                    ></FlatPicker>
                    <InputGroupAddon
                        addonType="append"
                    >
                        <span className="input-group-text py-0 px-1">
                            <Icon icon={calendarRange} width="25px" />
                        </span>
                    </InputGroupAddon>
                </InputGroup>
                </FormGroup>
                </Col>
                </Row>
                <Row>
                    <Col>
                <FormGroup style={{ width: '200px' }}>
                        <Label>Time In</Label>
                        <InputGroup>
                                <MaskedInput
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="__:__"
                                className={`form-control`}
                                name="time"
                                value={tempPunchIn.timeIn}
                                onChange={(e) => setTempPunchIn({
                                    ...tempPunchIn,
                                    timeIn: e.target.value
                                })}
                                style={{ textAlign: 'left' }}
                                required
                                pattern="^(0?[1-9]|1[0-2]):[0-5][0-9]$"
                            />
                                <ButtonGroup 
                                onClick={(e) => setTempPunchIn({
                                    ...tempPunchIn,
                                    timeInAmORPm: e.target.value
                                })} value={tempPunchIn.timeInAmORPm}
                                >
                                <Button type="radio" name="am-pm" value="am" color="primary" 
                                outline={tempPunchIn.timeInAmORPm !== 'am'}
                                >AM</Button>
                                <Button type="radio" name="am-pm" value="pm" color="primary" 
                                outline={tempPunchIn.timeInAmORPm !== 'pm'}
                                >PM</Button>
                            </ButtonGroup>
                        </InputGroup>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                <FormGroup style={{ width: '200px' }}>
                        <Label>Time Out</Label>
                        <InputGroup>
                                <MaskedInput
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="__:__"
                                className={`form-control`}
                                name="time"
                                value={tempPunchIn.timeOut}
                                onChange={(e) => setTempPunchIn({
                                    ...tempPunchIn,
                                    timeOut: e.target.value
                                })}
                                style={{ textAlign: 'left' }}
                                required
                                pattern="^(0?[1-9]|1[0-2]):[0-5][0-9]$"
                            />
                                <ButtonGroup 
                                onClick={(e) => setTempPunchIn({
                                    ...tempPunchIn,
                                    timeOutAmORPm: e.target.value
                                })} value={tempPunchIn.timeOutAmORPm}
                                >
                                <Button type="radio" name="am-pm" value="am" color="primary" 
                                outline={tempPunchIn.timeOutAmORPm !== 'am'}
                                >AM</Button>
                                <Button type="radio" name="am-pm" value="pm" color="primary" 
                                outline={tempPunchIn.timeOutAmORPm !== 'pm'}
                                >PM</Button>
                            </ButtonGroup>
                        </InputGroup>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Type:</Label>
                                <Select
                                    id="type"
                                    classNamePrefix="react-select"
                                    name="type"
                                    onChange={(selection) => {
                                        setTempPunchIn({
                                            ...tempPunchIn,
                                            punchInType: selection.value
                                        })
                                        }}
                                    value={punchInTypes.find(type => type.value === tempPunchIn.punchInType)}
                                    options={punchInTypes}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={editPunchIn}>
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EditPunchInModal;