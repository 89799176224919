import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useState } from 'react';
import { useEffect } from 'react';
import { ESApi } from '../api/ESApi';
import EmployeeModal from './EmployeeModal';
import Select from 'react-select';
import NoDataIndication from '../utility-components/NoDataIndication';
import EmployeeEditModal from '../custom-modals/EmployeeEditModal';
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import CreateEmployeeModal from '../custom-modals/CreateEmployeeModal';

const Employees = props => {

    const defaultSorted = [
        {
            dataField: 'payrollNumber',
            order: 'asc',
        },
    ];

    const columns = [
        {
            dataField: 'payrollNumber',
            text: 'Payroll Number',
            sort: true,
            style: { verticalAlign: 'middle', fontWeight: '800' }
        },
        {
            dataField: 'firstName',
            text: 'First Name',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'phone',
            text: 'Phone Number',
            sort: false,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: '',
            text: 'Department',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') return rowA.departments[0]?.departmentObject.name < rowB.departments[0]?.departmentObject.name ? -1 : 1;
                else return rowB.departments[0]?.departmentObject.name > rowA.departments[0]?.departmentObject.name ? 1 : -1;
              },
            formatter: (cell, row) => {
                return row.departments[0]?.departmentObject.name
            },
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: '',
            text: '',
            sort: false,
            formatter: (cell, row) => {
                return <EditButton employee={row} setEmployeeEdit={setEmployeeEdit}/>
            },
            style: { verticalAlign: 'middle' }
        }
    ];

    console.log({props})

    const query = new URLSearchParams(props.location.search);

    const [employees, setEmployees] = useState(null);

    const [employeeEdit, setEmployeeEdit] = useState(null)

    const [employeePayrollNumber, setEmployeePayrollNumber] = useState(null);

    const [departments, setDepartments] = useState([]);

    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const [createEmployeeModal, setCreateEmployeeModal] = useState(false)

    useEffect(() => {

        let payrollNumber = query.get('payrollNumber');

        if(payrollNumber){
            setEmployeePayrollNumber(parseInt(payrollNumber))
        } else {
            setEmployeePayrollNumber(null)
        }
    }, [props.location.search])

    useEffect(() => {
        ESApi.get('/departments')
        .then(res => setDepartments(res.data))
        .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        ESApi.get('/employees')
        .then(res => setEmployees(res.data))
        .catch(err => console.error(err))
    }, [])

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/employees`,
                search: 'payrollNumber=' + row.payrollNumber
            }

            props.history.push(location)
        }
    }

    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        sizePerPage: 25,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: 'All',
                value: employees?.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    function filterByDepartment(arr){
        return selectedDepartment ? arr.filter(employee => employee.departments[0]?.departmentObject.departmentCode === selectedDepartment.value) : arr
    }

    console.log({selectedDepartment})

    if(!employees)
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Employees...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
        )

    return (
        <Row className='mt-2'>
            <Col>
                <Card>
                    <CardBody>

                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={filterByDepartment(employees)}
                            columns={columns}
                            search
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                            {props => (
                                <React.Fragment>
                                    <Row className='justify-content-start align-items-center'>
                                        <Col sm={'auto'} className="mr-auto">
                                        <Button 
                                        onClick={() => setCreateEmployeeModal(true)}
                                        className="btn btn-danger mb-1">
                                            <i className="mdi mdi-plus-circle mr-2"></i>Add Employee
                                        </Button>
                                        </Col>
                                        <Col sm={'auto'}>
                                        <SearchBar {...props.searchProps} />
                                        </Col>
                                        <Col sm={'auto'} className="d-flex">
                                        <div style={{ width: '300px' }} className="mb-1">
                                        <Select       
                                            clearValue     
                                            placeholder="Select department"
                                            id="departments"
                                            classNamePrefix="react-select"
                                            name="departments"
                                            
                                            onChange={(selection) => {
                                                setSelectedDepartment(selection)
                                                    // setEdCode({
                                                    //     ...edCode,
                                                    //     edCode: selection.value
                                                    // })
                                                }}
                                            value={selectedDepartment}
                                            options={departments.map(department => ({ label: department.name, value: department.departmentCode }))}
                                        >
                                        </Select>
                                        </div>
                                        <div className='ml-2 mb-1'>
                                        <Button onClick={() => setSelectedDepartment(null)}>Clear</Button>
                                        </div>
                                        </Col>
                                        {/* <Col className="text-right">
                                            <ExportCSVButton {...props.csvProps} className="btn btn-primary">
                                                Export CSV
                                            </ExportCSVButton>
                                        </Col> */}
                                    </Row>

                                    <BootstrapTable
                                        {...props.baseProps}
                                        rowEvents={rowEvents}
                                        hover
                                        bordered={false}
                                        defaultSorted={defaultSorted}
                                        pagination={paginationFactory(paginationOptions)}
                                        wrapperClasses="table-responsive"
                                        noDataIndication={() => <NoDataIndication />}
                                    />
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </CardBody>
                </Card>
                {employeeEdit && <EmployeeEditModal employee={employeeEdit} toggle={() => setEmployeeEdit(null)} isOpen={employeeEdit}/>}
                <EmployeeModal payrollNumber={employeePayrollNumber}/>
                <CreateEmployeeModal isOpen={createEmployeeModal} toggle={() => setCreateEmployeeModal(false)}/>
            </Col>
        </Row>
    );
};

const EditButton = ({employee, setEmployeeEdit}) => (
    <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
        onClick={(e) => {
            e.stopPropagation()
            setEmployeeEdit(employee)}}
        >
        <Icon icon={pencilIcon} height="24" width="24" />
    </Button>
)

export default Employees;