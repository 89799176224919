import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import {DepartmentContext} from '../contexts/DepartmentContext';
import Select from 'react-select';
import MaskedInput from "react-text-mask";
import { useEffect } from 'react';
import { ESApi } from '../api/ESApi';
import { toast } from 'react-toastify';

const DepartmentEditModal = ({ isOpen, toggle, department}) => {

    const [departmentInfo, setDepartmentInfo] = useState({
        departmentCode: department.departmentCode,
        name: department.name,
        payrollWeekStartDay: department.payrollWeekStartDay || 2
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        let createDepartmentToast = toast.loading('Creating department...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        ESApi.put('/departments', departmentInfo)
        .then(res => {
            toast.update(createDepartmentToast, 
                { 
                    render: 'Department Created',
                    type: "success", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
        })
        .catch(err => {
            toast.update(createDepartmentToast, 
                { 
                    render: 'Error creating department',
                    type: "error", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        })
    }

    let payrollWeekDays = [
        { value: 2, label: 'Monday' },
        { value: 3, label: 'Tuesday' },
        { value: 4, label: 'Wednesday' },
        { value: 5, label: 'Thursday' },
        { value: 6, label: 'Friday' },
        { value: 7, label: 'Saturday' },
        { value: 1, label: 'Sunday' }
    ]

    return(
        <Modal
        toggle={toggle}
        isOpen={isOpen}>
            <ModalHeader
            toggle={toggle}
            >
               Edit Department: {departmentInfo.name}
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
            <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor='name'>Name</Label>
                                <Input
                                id="name"
                                name="name"
                                value={departmentInfo.name}
                                onChange={(e) => {
                                    setDepartmentInfo({
                                        ...departmentInfo,
                                        name: e.target.value
                                    })
                                }}
                                required
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor='departmentCode'>Department Code</Label>
                                <Input
                                id="departmentCode"
                                name="departmentCode"
                                value={departmentInfo.departmentCode}
                                disabled={true}
                                required
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                        <FormGroup>
                            <Label for="payrollWeekStartDay">Payroll Week Start Date</Label>
                                <Select
                                    id="payrollWeekStartDay"
                                    name="payrollWeekStartDay"
                                    classNamePrefix="react-select"
                                    onChange={(selection) => {
                                            setDepartmentInfo({
                                                ...departmentInfo,
                                                payrollWeekStartDay: selection.value
                                            })
                                        }}
                                    value={payrollWeekDays.find(day => day.value === departmentInfo.payrollWeekStartDay)}
                                    options={payrollWeekDays}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
   
            </ModalBody>
            <ModalFooter>
                <Button color="primary">
                    Submit
                </Button>{' '}
            </ModalFooter>
            </Form>
        </Modal>
    )
}

export default DepartmentEditModal;