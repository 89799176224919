import React from 'react';
import { Card, CardBody, CardHeader, CardFooter, Row, Col } from 'reactstrap';
import { Icon } from '@iconify/react';
import fileDocumentEditOutline from '@iconify/icons-mdi/file-document-edit-outline';
import { Link } from 'react-router-dom';
import cloudUploadOutline from '@iconify/icons-mdi/cloud-upload-outline';

const UploadsList = () => {
    return(
        <>
        <Row style={{ height: '30px' }}>

        </Row>
        <Row>
            <Col xs={'auto'}>
                <Link to={'/upload/custom-punch-in-template'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={cloudUploadOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Punch In Template</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
            <Col xs={'auto'}>
            <Link to={'/upload/churchill-punch-ins'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={cloudUploadOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Churchill Punch ins</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
            <Col xs={'auto'}>
            <Link to={'/upload/liebers-punch-ins'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={cloudUploadOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Liebers Punch ins</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
            <Col xs={'auto'}>
            <Link to={'/upload/fingercheck-punch-ins'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={cloudUploadOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Fingercheck Punch ins</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
            <Col xs={'auto'}>
            <Link to={'/upload/quickway-punch-ins'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={cloudUploadOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Quickway Punch ins</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
            <Col xs={'auto'}>
            <Link to={'/upload/fabuwood-punch-ins'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={cloudUploadOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Fabuwood Punch ins</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
        </Row>
        </>
    )
} 

export default UploadsList;