import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Table, CardHeader, Button, FormGroup, Label, InputGroup, InputGroupAddon, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { ESApi } from '../api/ESApi';
import SimpleBar from 'simplebar-react';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import AddPunchInModal from '../custom-modals/AddPunchInModal';
import AddEdCodeModal from '../custom-modals/AddEdCodeModal';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import deleteIcon from '@iconify/icons-mdi/delete';
import { confirm } from '../utility-components/ConfirmModal';
import { toast } from 'react-toastify';
import monitorIcon from '@iconify/icons-mdi/monitor';
import cellphoneIcon from '@iconify/icons-mdi/cellphone';
import clockPlusOutline from '@iconify/icons-mdi/clock-plus-outline';
import fileCheckOutline from '@iconify/icons-mdi/file-check-outline';
import noteTextOutline from '@iconify/icons-mdi/note-text-outline';
import EditPunchInModal from '../custom-modals/EditPunchInModal';
import pencilIcon from '@iconify/icons-mdi/pencil';
import Select from "react-select";
import { add, sub } from "date-fns";
import { useContext } from 'react';
import { DepartmentContext } from '../contexts/DepartmentContext';
import accountHardHat from '@iconify/icons-mdi/account-hard-hat';
import foodForkDrink from '@iconify/icons-mdi/food-fork-drink';

const Employee = props => {

    const { departments } = useContext(DepartmentContext);
    
    const start = startOfWeek(new Date(), {weekStartsOn: 1});
    const end = endOfWeek(new Date(), {weekStartsOn: 1}); 
    
    const [dateRange, setDateRange] = useState([start, end])

    console.log({dateRange})

    const [payrollPeriod, setPayrollPeriod] = useState(null);

    const [payrollPeriodDate, setPayrollPeriodDate] = useState(null);

    const [payrollPeriodYear, setPayrollPeriodYear] = useState(null);

    const [payrollPeriodSelectOptions, setPayrollPeriodSelectOptions] = useState([]);

    const [employeeDepartments, setEmployeeDepartments] = useState([])

    const [missedPunches, setMissedPunches] = useState([])

    useEffect(() => {
        ESApi.get(`/employees/${payrollNumber}/departments`)
        .then(res => {
            setEmployeeDepartments(res.data)
        })
    }, [])

    useEffect(() => {
        ESApi.get(`/employees/${payrollNumber}/punch-ins`, { params: { missed: true } })
        .then(res => {
            setMissedPunches(res.data)
        })
    }, [])

    useEffect(() => {
        ESApi.get('/payroll/currentPayrollPeriod')
        .then(({data}) => {
            setPayrollPeriod(data.payrollPeriod)
            setPayrollPeriodDate(data.currentPayrollPeriodDate)
            setPayrollPeriodYear(new Date(data.currentPayrollPeriodDate).getFullYear())
            let options = [{
                label: `${data.payrollPeriod} - ${new Date(data.currentPayrollPeriodDate).toLocaleDateString()}`,
                year: new Date(data.currentPayrollPeriodDate).getFullYear(),
                value: data.payrollPeriod
            }];

            let nextWeeksDate = add(new Date(data.currentPayrollPeriodDate), {
                weeks: 1
            })

            options.push({
                label: `${data.payrollPeriod + 1 > 52 ? (data.payrollPeriod + 1) - 52 : data.payrollPeriod + 1} - ${nextWeeksDate.toLocaleDateString()}`,
                year: nextWeeksDate.getFullYear(),
                value: data.payrollPeriod + 1 > 52 ? (data.payrollPeriod + 1) - 52 : data.payrollPeriod + 1
            })
            

            for(let i = 1; i < 10; i++){

                let thisWeeksDate = sub(new Date(data.currentPayrollPeriodDate), {
                    weeks: i
                })

                options.unshift({
                    label: `${data.payrollPeriod - i < 1 ? 52 + (data.payrollPeriod - i) : data.payrollPeriod - i} - ${thisWeeksDate.toLocaleDateString()}`,
                    year: thisWeeksDate.getFullYear(),
                    value: data.payrollPeriod - i < 1 ? 52 + (data.payrollPeriod - i) : data.payrollPeriod - i
                })
            }

            setPayrollPeriodSelectOptions(options)
        })
    }, [])

    useEffect(() => {
        
        if(payrollPeriod && payrollPeriodYear){
            setLoading(true)
            ESApi.get(`/employees/${payrollNumber}`, { params: { payrollPeriod, payrollPeriodYear } })
            .then(res => {
                setEmployee(res.data)})
            .catch(err => console.error(err))
            .finally(setLoading(false))
        }

    }, [payrollPeriod, payrollPeriodYear])

    function format(inputDate) {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${month}/${date}/${year}`;
    }

    function reFormat(inputDate){
        let splitDate = inputDate.split('/');
        let month = splitDate[0];
        let day = splitDate[1];
        let year = splitDate[2];

        return `${year}-${month}-${day}`
    }



    let payrollNumber = props.match.params.employeePayrollNumber;

    console.log(props)

    const [employee, setEmployee] = useState(null)

    const [loading, setLoading] = useState(true)

    const [isPunchInModalOpen, setIsPunchInModalOpen] = useState(false);

    const [punchInEdit, setPunchInEdit] = useState(null);

    function togglePunchInModal(){
        setIsPunchInModalOpen(!isPunchInModalOpen)
    }

    const [isEdCodeModalOpen, setIsEdCodeModalOpen] = useState(false);

    function toggleEdCodeModal(){
        setIsEdCodeModalOpen(!isEdCodeModalOpen)
    }

    function togglePunchInEditModal(){
        setPunchInEdit(null)
    }

    async function deletePunchIn(id){
        if(await confirm("Remove Punch In? This cannot be undone.", "Removing Punch In...", "Remove", "Cancel", "danger", "danger") === false) return

        ESApi.delete('/punch-ins/' + id)
        .then(res => {
            toast.success('Punch in successfully deleted')
            setEmployee({
                ...employee,
                punchIns: employee.punchIns.filter(punchIn => punchIn.id !== id)
            })
        })
        .catch(err => toast.error('Error deleting punch in'))
    }

    async function deleteEdCode(id){
        if(await confirm("Remove Ed Code entry? This cannot be undone.", "Removing Ed Code entry...", "Remove", "Cancel", "danger", "danger") === false) return

        ESApi.delete('/ed-codes/' + id)
        .then(res => {
            toast.success('Ed code successfully deleted')
            setEmployee({
                ...employee,
                edCodes: employee.edCodes.filter(edCode => edCode.id !== id)
            })
        })
        .catch(err => toast.error('Error deleting ed code'))
    }

    if(loading || !employee)
    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Employee...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
    )

    return(
        <>  
            <Row className='my-2 align-items-center justify-space-between'>
                <Col>
                    <h3>{employee.firstName} {employee.lastName}</h3>
                </Col>
                <Col md={'auto'}>
                    {/* <FormGroup className='mb-0 ml-3 d-flex'>
                        <Label className='text-white d-flex align-items-center mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>Date Range : </Label>
                        <InputGroup style={{ width: '350px' }}>
                                <FlatPicker
                                    className='form-control rounded-left bg-white'
                                    value={dateRange}
                                    onChange={(date) => setDateRange(date)}
                                    options={
                                        {
                                            // maxDate: 'today',
                                            mode: "range",
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d",
                                        }
                                    }
                                    placeholder="Choose date..."
                                ></FlatPicker>
                                <InputGroupAddon
                                    addonType="append"
                                >
                                    <span className="input-group-text py-0 px-1">
                                        <Icon icon={calendarRange} width="25px" />
                                    </span>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup> */}
                        <Col md={'auto'}>
                        <FormGroup className="d-flex align-items-center mb-0">
                            <Label for="ed-codes" className="m-0 mr-1">Payroll Period:</Label>
                                <div style={{ minWidth: '300px' }}>
                                <Select
                                    id="payroll-period"
                                    classNamePrefix="react-select"
                                    name="payroll-period"
                                    isDisabled={loading}
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setPayrollPeriodYear(selection.year)
                                            setPayrollPeriod(selection.value)
                                        }}
                                    value={payrollPeriodSelectOptions.find(option => option.value === payrollPeriod)}
                                    options={payrollPeriodSelectOptions}
                                >
                                </Select>
                                </div>
                        </FormGroup>
            </Col>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center text-white bg-primary'><h4>Employee</h4></CardHeader>
                        <CardBody className='bg-white'>
                        <div>
                                <p>Payroll Number:<br /><b>{employee.payrollNumber}</b></p>
                                <p>First Name:<br /><b>{employee.firstName}</b></p>
                                <p>Last Name:<br /><b>{employee.lastName}</b></p>
                                <p>Phone Number:<br /><b>{employee.phone}</b></p>
                                {/* <p>Department:<br /><b>{employee.departments[0]?.departmentObject.name}</b></p> */}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={9}>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center text-white bg-primary'>
                            <h4>Punch Card</h4>
                            <Button
                            onClick={() => setIsPunchInModalOpen(true)}
                            >Add Punch In</Button></CardHeader>
                        <SimpleBar style={{ minHeight: '350px', maxHeight: '350px' }}>
                            <Table className="mb-0" striped>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                <tr style={{ whiteSpace:'nowrap' }}>
                                    <th>Department Name</th>
                                    <th>Date</th>
                                    <th>Time In</th>
                                    <th>Time Out</th>
                                    <th>Hours</th>
                                    <th>Type</th>
                                    <th>Method</th>
                                    <th style={{ width: '30px' }}></th>
                                    <th style={{ width: '30px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.punchIns.map((punchIn, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* the following prevents the date from offsetting the time zone when converted to date */}
                                            <td style={{ verticalAlign: 'middle' }}>{departments.find(dep => dep.departmentCode === punchIn.departmentCode)?.name}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{format(new Date(punchIn.date.replace('-', '/')))}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : "---------"}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{punchIn.hrs}</td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchInType === 'reg' &&
                                                <div title={'Regular'}>
                                                <Icon icon={accountHardHat} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'lunch' &&
                                                <div title={'Lunch'}>
                                                <Icon icon={foodForkDrink} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'break' &&
                                                <div title={'Break'}>
                                                    <b>Break</b>
                                                </div>
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchAddedFrom === 'custom_entry' &&
                                                <div title={'System Entry'}>
                                                <Icon icon={monitorIcon} height="24" width="24"/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('app')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={cellphoneIcon} height="24" width="24" title={punchIn.inPunchMethod}/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('clock')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={clockPlusOutline} height="24" width="24"/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('manual')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={fileCheckOutline} height="24" width="24" title={punchIn.inPunchMethod}/>
                                                </div>
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                
                                                <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                                onClick={() => setPunchInEdit(punchIn)}
                                                >
                                                    <Icon icon={pencilIcon} height="24" width="24" />
                                                </Button>
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchAddedFrom === 'custom_entry' && 
                                                <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                                onClick={() => deletePunchIn(punchIn.id)}
                                                >
                                                    <Icon icon={deleteIcon} height="24" width="24" />
                                                </Button>}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        </SimpleBar>
                    </Card>
                </Col>
            </Row>
            <Row>
            <Col md={6}>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center text-white bg-primary'><h4>ED Codes</h4>
                        <Button
                        onClick={() => setIsEdCodeModalOpen(true)}
                        >Add ED Code</Button></CardHeader>
                        <SimpleBar style={{ minHeight: '350px', maxHeight: '350px' }}>
                            <Table className="mb-0" striped>
                                <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                    <tr style={{ whiteSpace:'nowrap' }}>
                                        <th>Date</th>
                                        <th>Code</th>
                                        <th>Qty</th>
                                        <th>Description</th>
                                        <th>Notes</th>
                                        <th style={{ width: '30px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employee.edCodes.map((ed, index) => {
                                        const { description, quantityType } = ed.edCodeObject;
                                        return (
                                            <tr key={index}>
                                                <td style={{ verticalAlign: 'middle' }}>{new Date(ed.createdAt).toLocaleDateString()}</td>
                                                <td style={{ verticalAlign: 'middle', textTransform: 'capitalize' }}>{ed.edCode}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{quantityType === 'total_sum' ? 
                                                <span>{ed.edCode.startsWith('d') && <b>-</b>}<b>$</b>{ed.qty}</span> : <span>{ed.qty} <b>hrs</b></span>}</td>
                                                <td style={{ verticalAlign: 'middle', textTransform: 'capitalize' }}>{description}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{CommentCell(ed.notes, index)}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                                onClick={() => deleteEdCode(ed.id)}
                                                >
                                                    <Icon icon={deleteIcon} height="24" width="24" />
                                                </Button>
                                            </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </SimpleBar>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card style={{ minHeight: '400px' }}>
                        <CardHeader style={{ minHeight: '63px' }} className="text-white bg-primary d-flex align-items-center"><h4>Departments</h4> - Last 4 months</CardHeader>
                        <SimpleBar style={{ minHeight: '350px', maxHeight: '350px' }}>
                        <Table className="mb-0" striped>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                <tr>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Punch In Hrs</th>
                                    <th>Ed Codes Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeDepartments.sort((a, b) => a.departmentCode > b.departmentCode ? 1 : -1).map((department, index) => {
                                        const { departmentCode, punchInSum, edCodeSum } = department;
                                        return (
                                            <tr key={index}>
                                                <td>{departments.find(dep => dep.departmentCode === departmentCode)?.name}</td>
                                                <td>{departmentCode}</td>
                                                <td>{punchInSum}</td>
                                                <td>{edCodeSum}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        </SimpleBar>
                    </Card>
                </Col>
            </Row>
            <Row>
            <Col md={9}>
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center text-white bg-primary'>
                            <h4>Missed Punches</h4>
                        </CardHeader>
                        <SimpleBar style={{ minHeight: '350px', maxHeight: '350px' }}>
                            <Table className="mb-0" striped>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                <tr style={{ whiteSpace:'nowrap' }}>
                                    <th>Department Code</th>
                                    <th>Date</th>
                                    <th>Time In</th>
                                    <th>Time Out</th>
                                    <th>Hours</th>
                                    <th>Type</th>
                                    <th>Method</th>
                                    <th style={{ width: '30px' }}></th>
                                    <th style={{ width: '30px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {missedPunches.map((punchIn, index) => {
                                    return (
                                        <tr key={index}>
                                            {/* the following prevents the date from offsetting the time zone when converted to date */}
                                            <td style={{ verticalAlign: 'middle' }}>{departments.find(dep => dep.departmentCode === punchIn.departmentCode)?.name}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{format(new Date(punchIn.date.replace('-', '/')))}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : "---------"}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{punchIn.hrs}</td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchInType === 'reg' &&
                                                <div title={'Regular'}>
                                                <Icon icon={accountHardHat} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'lunch' &&
                                                <div title={'Lunch'}>
                                                <Icon icon={foodForkDrink} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'break' &&
                                                <div title={'Break'}>
                                                    <b>Break</b>
                                                </div>
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchAddedFrom === 'custom_entry' &&
                                                <div title={'System Entry'}>
                                                <Icon icon={monitorIcon} height="24" width="24"/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('app')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={cellphoneIcon} height="24" width="24" title={punchIn.inPunchMethod}/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('clock')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={clockPlusOutline} height="24" width="24"/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('manual')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={fileCheckOutline} height="24" width="24" title={punchIn.inPunchMethod}/>
                                                </div>
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                
                                                <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                                onClick={() => setPunchInEdit(punchIn)}
                                                >
                                                    <Icon icon={pencilIcon} height="24" width="24" />
                                                </Button>
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchAddedFrom === 'custom_entry' && 
                                                <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
                                                onClick={() => deletePunchIn(punchIn.id)}
                                                >
                                                    <Icon icon={deleteIcon} height="24" width="24" />
                                                </Button>}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        </SimpleBar>
                    </Card>
                </Col>
            </Row>
            {isPunchInModalOpen && <AddPunchInModal payrollNumber={employee.payrollNumber} employee={employee} 
            department={employeeDepartments.sort((a, b) => parseFloat(a.punchInSum) > parseFloat(b.punchInSum) ? -1 : 1)[0]?.departmentCode} isOpen={isPunchInModalOpen} toggle={togglePunchInModal} />}
            {isEdCodeModalOpen && <AddEdCodeModal payrollNumber={employee.payrollNumber} employee={employee} department={employeeDepartments.sort((a, b) => parseFloat(a.punchInSum) > parseFloat(b.punchInSum) ? -1 : 1)[0]?.departmentCode} isOpen={isEdCodeModalOpen} toggle={toggleEdCodeModal} />}
            <EditPunchInModal
            employee={employee} 
            toggle={togglePunchInEditModal}
            punchIn={punchInEdit}
            /> 
        </>
    )
}

function CommentCell(cell, id){

    if(!cell)
    return ''

    return(
    <div id={`comment-popover-${id}`} className="d-flex justify-content-center popover-comment" style={{ width: 'max-content' }}>
        <Icon icon={noteTextOutline} height="25" width="25" className='comment-icon' />
        <UncontrolledPopover placement="top" target={`comment-popover-${id}`} trigger="hover">
                {/* <PopoverHeader>Title goes here</PopoverHeader> */}
                <PopoverBody>{cell}</PopoverBody>
        </UncontrolledPopover>
    </div>
    )
}

export default Employee;