import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useState } from 'react';
import { useEffect } from 'react';
import { ESApi } from '../api/ESApi';
import EmployeeModal from './EmployeeModal';
import Select from 'react-select';
import { AvForm } from 'availity-reactstrap-validation';
import Form from 'reactstrap/es/Form';
import FormGroup from 'reactstrap/es/FormGroup';
import Label from 'reactstrap/es/Label';
import NoDataIndication from '../utility-components/NoDataIndication';
import CreateDepartmentModal from '../custom-modals/CreateDepartmentModal';
import DepartmentEditModal from '../custom-modals/DepartmentEditModal';
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import CreateLunchRulesModal from '../custom-modals/CreateLunchRulesModal';
import CreateSettingsModal from '../custom-modals/CreateSettingsModal';
import foodIcon from '@iconify/icons-mdi/food';
import gearIcon from '@iconify/icons-mdi/gear';

function returnDayName(day){
    switch(day) {
        case 1:
          return 'Sunday'
        case 2:
            return 'Monday'
        case 3:
            return 'Tuesday'
        case 4:
            return 'Wednesday'
        case 5:
            return 'Thursday'
        case 6:
            return 'Friday'
        case 7:
            return 'Saturday'
        default:
            return 'Monday'
      }
}

const defaultSorted = [
    {
        dataField: 'name',
        order: 'asc',
    },
];

const Departments = props => {

    const [createDepartmentModal, setCreateDepartmentModal] = useState(false)

    const [departments, setDepartments] = useState(null);

    const [departmentEdit, setDepartmentEdit] = useState(null);

    const [lunchEdit, setLunchEdit] = useState(null)

    const [settings, setSettings] = useState(null);

    useEffect(() => {
        ESApi.get('/departments')
        .then(res => setDepartments(res.data))
        .catch(err => console.error(err))
    }, [])

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    const columns = [
        {
            dataField: 'name',
            text: 'Department Name',
            sort: true,
            style: { verticalAlign: 'middle', fontWeight: '800' }
        },
        {
            dataField: 'departmentCode',
            text: 'Department Code',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'employeeCount365',
            text: 'Employees',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') return parseInt(a) < parseInt(b) ? -1 : 1;
                else return parseInt(a) < parseInt(b) ? 1 : -1;
              },
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'payrollWeekStartDay',
            text: 'Payroll Start Day',
            sort: false,
            style: { verticalAlign: 'middle' },
            formatter: (cell) => returnDayName(cell)
        },
        {
            dataField: '',
            text: '',
            sort: false,
            formatter: (cell, row) => {
                return (
                    <div className="d-flex">
                        <EditButton department={row} setDepartmentEdit={setDepartmentEdit}/>
                        <EditLunchButton department={row} setLunchEdit={setLunchEdit}/>
                        <SettingsButton department={row} setSettings={setSettings}/>
                    </div>
                );
            },
            style: { verticalAlign: 'middle' }
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing {from} to {to} of {size} Results
        </span>
    );

    const paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: 'All',
                value: departments?.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    if(!departments)
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Employees...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
        )

    return (
        <Row className='mt-2'>
            <Col>
                <Card>
                    <CardBody>

                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={departments}
                            columns={columns}
                            search
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                            {props => (
                                <React.Fragment>
                                    <Row className='justify-content-between'>
                                        <Col xs="auto">
                                    <Button className="btn btn-danger"
                                    onClick={() => setCreateDepartmentModal(true)}
                                    >
                                        <i className="mdi mdi-plus-circle mr-2"></i>Add Department
                                    </Button>
                                    </Col>
                                    <Col xs="auto">
                                        <SearchBar {...props.searchProps} />
                                        {/* <Col className="text-right">
                                            <ExportCSVButton {...props.csvProps} className="btn btn-primary">
                                                Export CSV
                                            </ExportCSVButton>
                                        </Col> */}
                                    </Col>
                                    </Row>

                                    <BootstrapTable
                                        {...props.baseProps}
                                        hover
                                        bordered={false}
                                        defaultSorted={defaultSorted}
                                        pagination={paginationFactory(paginationOptions)}
                                        wrapperClasses="table-responsive"
                                        noDataIndication={NoDataIndication}
                                    />
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </CardBody>
                </Card>
                {settings && <CreateSettingsModal department={settings} toggle={() => setSettings(null)} isOpen={!!settings}/>}
                {lunchEdit && <CreateLunchRulesModal department={lunchEdit} toggle={() => setLunchEdit(null)} isOpen={!!lunchEdit}/>}
                {departmentEdit && <DepartmentEditModal department={departmentEdit} toggle={() => setDepartmentEdit(null)} isOpen={!!departmentEdit}/>}
                <CreateDepartmentModal isOpen={createDepartmentModal} toggle={() => setCreateDepartmentModal(false)}/>
            </Col>
        </Row>
    );
};

const EditButton = ({department, setDepartmentEdit}) => (
    <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
        onClick={(e) => {
            e.stopPropagation()
            setDepartmentEdit(department)}}
        >
        <Icon icon={pencilIcon} height="24" width="24" />
    </Button>
)

const EditLunchButton = ({department, setLunchEdit}) => (
    <Button className='link-button p-0 table-button' color="link" style={{ color: '#6c757d', fontSize: '22px' }}
        onClick={(e) => {
            e.stopPropagation()
            setLunchEdit(department)}}
        >
        <Icon icon={foodIcon} height="24" width="24" />
    </Button>
)

const SettingsButton = ({ department, setSettings }) => (
    <Button
        className="link-button p-0 table-button"
        color="link"
        style={{ color: '#6c757d', fontSize: '22px' }}
        onClick={(e) => {
            e.stopPropagation();
            setSettings(department)
        }}>
        <Icon icon={gearIcon} height="24" width="24" />
    </Button>
);

export default Departments;