import React from 'react';

const NoDataIndication = () => {
    return(
        <div style={{ height: '170px', border: '2px dashed gray', borderRadius: '7px' }} className="d-flex justify-content-center align-items-center no-data">
            <h4>No Results ...</h4>
        </div>
    )
}

export default NoDataIndication;