import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledButtonDropdown } from 'reactstrap';
import { ESApi } from '../../api/ESApi';
import { DepartmentContext } from '../../contexts/DepartmentContext';
import SimpleBar from 'simplebar-react';
import { Icon } from '@iconify/react';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import EditPunchInModal from '../../custom-modals/EditPunchInModal';
import accountHardHat from '@iconify/icons-mdi/account-hard-hat';
import foodForkDrink from '@iconify/icons-mdi/food-fork-drink';

function formatToUSDate(inputDate) {
    let splitDate = inputDate.split('-');
    let year = splitDate[0];
    let month = splitDate[1];
    let day = splitDate[2];

    return `${month}/${day}/${year}`;
}

const MissedPunchIns = (props) => {

    const { departments } = useContext(DepartmentContext);

    const [missedPunchIns, setMissedPunchIns] = useState(null);

    const [loading, setLoading] = useState(true)

    const [punchInEdit, setPunchInEdit] = useState(null);
    const [employee, setEmployee] = useState(null)

    useEffect(() => {
        ESApi.get('/punch-ins/missed')
        .then(res => {
            setMissedPunchIns(res.data)
        })
        .catch(err => {
            toast.error('Cannot get missed Punch ins')
        })
        .finally(() => setLoading(false))
    }, [])

    function toggleModal(){
        setEmployee(null)
        setPunchInEdit(null)
    }

    if(loading)
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Missed Punch Ins...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
        )

    return(
        <Card>
            <CardBody>
                <h4>Missed Punch Ins</h4>
                <SimpleBar style={{ maxHeight: '400px' }}>
                <Table className="mb-0" size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Department</th>
                            <th>Date</th>
                            <th>In</th>
                            <th>Out</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {missedPunchIns.map((punchIn, index) => {
                            const { employeeObject } = punchIn;
                            return (
                                <tr key={index}>
                                    <td>{employeeObject.lastName + ', ' + employeeObject.firstName}</td>
                                    <td>{departments.find(dep => dep.departmentCode === punchIn.departmentCode)?.name}</td>
                                    <td>{formatToUSDate(punchIn.date)}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{punchIn.timeIn ? new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : "---"}</td>
                                    <td>{punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : "---"}</td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchInType === 'reg' &&
                                                <div title={'Regular'}>
                                                <Icon icon={accountHardHat} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'lunch' &&
                                                <div title={'Lunch'}>
                                                <Icon icon={foodForkDrink} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'break' &&
                                                <div title={'Break'}>
                                                    <b>Break</b>
                                                </div>
                                                }
                                            </td>
                                    <td style={{ verticalAlign: 'middle', position: 'relative' }}>       
                                    <UncontrolledButtonDropdown className="position-absolute" style={{ right: '5px', top: '0px'}}>
                                            <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                                <Icon icon={dotsHorizontal} />
                                            </DropdownToggle>
                                        <DropdownMenu right style={{ minWidth: 'max-content' }}>
                                            {/* <DropdownItem className='px-3 py-1'
                                            ><Icon icon={accountDetails} width="20" height="20" className="mr-1"/>Edit Details</DropdownItem> */}
                                            <DropdownItem className='px-3 py-1'
                                            onClick={() => {
                                                setEmployee(employeeObject)
                                                setPunchInEdit(punchIn)
                                            }}
                                            >Resolve</DropdownItem>
                                        </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                </SimpleBar>
                <EditPunchInModal
                    employee={employee} 
                    toggle={toggleModal}
                    punchIn={punchInEdit}
                /> 
            </CardBody>
        </Card>
    )
}

export default MissedPunchIns;