import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupAddon, Label, Row, FormGroup, ButtonGroup, Col, Input } from 'reactstrap';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import MaskedInput from "react-text-mask";
import { useState } from "react";
import { ESApi } from "../api/ESApi";
import { toast } from "react-toastify";
import Select from "react-select";
import { add, sub } from 'date-fns'
import { useContext } from "react";
import { DepartmentContext } from "../contexts/DepartmentContext";

const edCodes = [
    {
        label: 'E02 - Regular Pay',
        value: 'e02',
        amountType: 'hrs'
    },
    {
        label: 'E03 - Overtime',
        value: 'e03',
        amountType: 'hrs'
    },
    {
        label: 'E04 - Holiday',
        value: 'e04',
        amountType: 'hrs'
    },
    {
        label: 'R01 - Expense Reimbursement',
        value: 'r01',
        amountType: 'total_sum'
    },
    {
        label: 'E10 - Bonus',
        value: 'e10',
        amountType: 'total_sum'
    },
    {
        value: 'd01',
        label: 'D01 - Deduction',
        amountType: 'total_sum'
    },
    {
        value: 'd03',
        label: 'D03 - Misc Deduction',
        amountType: 'total_sum'
    },
    {
        value: 'd04',
        label: 'D04 - Other Standard Deduction',
        amountType: 'total_sum'
    },
    {
        value: 'd41',
        label: 'D41 - Child Support 1',
        amountType: 'total_sum'
    },
    {
        value: 'd51',
        label: 'D51 - Garnishment 1',
        amountType: 'total_sum'
    },
    {
        value: 'e07',
        label: 'E07 - PTO',
        amountType: 'hrs'
    },
    {
        value: 'e06',
        label: 'E06 - Sick',
        amountType: 'hrs'
    },
    {
        value: 'e11',
        label: 'E11 - Commission',
        amountType: 'total_sum'
    },
    {
        value: 'e13',
        label: 'E13 - Misc Earnings',
        amountType: 'total_sum'
    },
    {
        value: 'n01',
        label: 'N01 - 1099 Earnings',
        amountType: 'total_sum'
    },
    {
        value: 'n11',
        label: 'N11 - 1099 Commission',
        amountType: 'total_sum'
    },
    {
        value: 'nysfpl',
        label: 'NYSFPL - NYS Paid Family Leave',
        amountType: 'hrs'
    }
]

const AddEdCodeModal = ({ payrollNumber, employee, department, isOpen, toggle }) => {

    const { departments } = useContext(DepartmentContext)

    const [payrollPeriod, setPayrollPeriod] = useState(null);

    const [payrollPeriodYear, setPayrollPeriodYear] = useState(null);

    const [departmentCode, setDepartmentCode] = useState(department);

    const [payrollPeriodOptions, setPayrollPeriodOptions] = useState([]);

    console.log({departmentCode})

    useEffect(() => {
        if(isOpen && departmentCode){
            ESApi.get('/payroll/departments/' + departmentCode)
            .then(res => {
                setPayrollPeriod(res.data.currentPayrollPeriod)
                let options = [{
                    label: `${res.data.currentPayrollPeriod}: ${new Date(res.data.startDate).toLocaleDateString()}  - ${new Date(res.data.endDate).toLocaleDateString()}`,
                    startDate: new Date(res.data.startDate),
                    endDate: new Date(res.data.endDate),
                    year: new Date().getFullYear(),
                    value: res.data.currentPayrollPeriod
                }];

                let nextWeeksStartDate = add(new Date(res.data.startDate), {
                    weeks: 1
                })

                let nextWeeksEndDate = add(new Date(res.data.endDate), {
                    weeks: 1
                })
    
                options.push({
                    label: `${res.data.currentPayrollPeriod + 1 > 52 ? (res.data.currentPayrollPeriod + 1) - 52 : res.data.currentPayrollPeriod + 1}: ${new Date(nextWeeksStartDate).toLocaleDateString()}  - ${new Date(nextWeeksEndDate).toLocaleDateString()}`,
                    startDate: new Date(nextWeeksStartDate),
                    endDate: new Date(nextWeeksEndDate),
                    year: res.data.currentPayrollPeriod + 1 > 52 ? new Date().getFullYear() + 1 : new Date().getFullYear(),
                    value: res.data.currentPayrollPeriod + 1 > 52 ? (res.data.currentPayrollPeriod + 1) - 52 : res.data.currentPayrollPeriod + 1
                })
                
                for(let i = 1; i < 5; i++){

                    let startDate = sub(new Date(res.data.startDate), {
                        weeks: i
                    })

                    let endDate = sub(new Date(res.data.endDate), {
                        weeks: i
                    })

                    options.unshift({
                        label: `${res.data.currentPayrollPeriod - i < 1 ? 52 + (res.data.currentPayrollPeriod - i) : res.data.currentPayrollPeriod - i}: ${startDate.toLocaleDateString()}  - ${endDate.toLocaleDateString()}`,
                        startDate: startDate,
                        endDate: endDate,
                        year: res.data.currentPayrollPeriod - i < 1 ? new Date().getFullYear() - 1 : new Date().getFullYear(),
                        value: res.data.currentPayrollPeriod - i < 1 ? 52 + (res.data.currentPayrollPeriod - i) : res.data.currentPayrollPeriod - i
                    })
                }

                setPayrollPeriodOptions(options)
            })
        }
    }, [isOpen, departmentCode])

    const [edCode, setEdCode] = useState({
        edCode: 'null',
        qty: '0',
        notes: '',
        payrollPeriod: null,
        payrollPeriodYear: new Date().getFullYear() 
    })

    const addEdCode = () => {

        let edCodeObject = {
            edCode: edCode.edCode,
            qty: parseFloat(edCode.qty),
            payrollNumber: payrollNumber,
            departmentCode: departmentCode,
            notes: edCode.notes,
            payrollPeriod: payrollPeriod,
            payrollPeriodYear: payrollPeriodOptions.find(option => option.value === payrollPeriod).year
        }

        ESApi.post('/ed-codes', edCodeObject)
        .then(res => {
            toast.success('Ed code added')
            window.location.reload()
        })
        .catch(err => toast.error('Error adding ed code'))
    }

    return(
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            >
            <ModalHeader toggle={toggle}>
                Add Ed Code
            </ModalHeader>
            <ModalBody>
                <Row className="mb-2">
                    <Col>
                        <div>
                            <b>Employee:</b> {employee.firstName + ' ' + employee.lastName}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Department:</Label>
                            {console.log({departmentCode})}
                                <Select
                                    id="departments"
                                    classNamePrefix="react-select"
                                    name="departments"
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setDepartmentCode(selection.value)
                                        }}
                                    value={!departmentCode ? null : departments.map(department => ({label: department.name, value: department.departmentCode})).find(dep => dep.value === departmentCode)}
                                    options={departments.map(department => ({label: department.name, value: department.departmentCode}))}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Payroll Period:</Label>
                                <Select
                                    id="payroll-period"
                                    classNamePrefix="react-select"
                                    name="payroll-period"
                                    isDisabled={!departmentCode}
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setPayrollPeriodYear(selection.year)
                                            setPayrollPeriod(selection.value)
                                        }}
                                    value={payrollPeriodOptions.find(option => option.value === payrollPeriod)}
                                    options={payrollPeriodOptions}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="ed-codes">Ed Code:</Label>
                                <Select
                                    id="ed-codes"
                                    classNamePrefix="react-select"
                                    name="ed-codes"
                                    onChange={(selection) => {
                                        console.log({selection})
                                            setEdCode({
                                                ...edCode,
                                                edCode: selection.value
                                            })
                                        }}
                                    value={edCodes.find(code => code.value === edCode.edCode)}
                                    options={edCodes}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="qty">Qty</Label>
                            <InputGroup>
                            {edCodes.find(code => code.value === edCode.edCode)?.amountType === 'total_sum' && 
                                <InputGroupAddon
                                addonType="prepend"
                                >
                                    <span className="input-group-text py-0 px-2">
                                        $
                                    </span>
                                </InputGroupAddon>}
                                <Input
                                    placeholder="Quantity..."
                                    value={edCode.qty}
                                    onChange={(e) => setEdCode({
                                        ...edCode,
                                        qty: e.target.value
                                    })}
                                />
                                {edCodes.find(code => code.value === edCode.edCode)?.amountType === 'hrs' && 
                                <InputGroupAddon
                                addonType="append"
                                >
                                    <span className="input-group-text py-0 px-2">
                                        Hrs
                                    </span>
                                </InputGroupAddon>}
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="qty">Notes <small>{`(optional)`}</small></Label>
                            <Input
                            type='textarea'
                            rows={2}
                            value={edCode.notes}
                            onChange={(e) => setEdCode({
                                ...edCode,
                                notes: e.target.value
                            })}
                            >
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button 
                onClick={addEdCode}
                color="primary">
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddEdCodeModal;