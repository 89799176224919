import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import {DepartmentContext} from '../contexts/DepartmentContext';
import Select from 'react-select';
import MaskedInput from "react-text-mask";
import { useEffect } from 'react';
import { ESApi } from '../api/ESApi';
import { toast } from 'react-toastify';

const EmployeeEditModal = ({employee, isOpen, toggle}) => {

    console.log({employee})

    const { departments } = useContext(DepartmentContext);

    const [employeeInfo, setEmployeeInfo] = useState(employee);

    useEffect(() => {
        if(employee.departments.length){
            setEmployeeInfo({
                ...employeeInfo,
                departmentCode: employee.departments[0].departmentCode,
                assignedCompanyId: employee.departments[0].assignedCompanyId
            })
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        let editingEmployeeToast = toast.loading('Editing employee...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        ESApi.put('/employees/' + employeeInfo.payrollNumber, employeeInfo)
        .then(res => {
            toast.update(editingEmployeeToast, 
                { 
                    render: 'Employee Updated',
                    type: "success", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
        })
        .catch(err => {
            toast.update(editingEmployeeToast, 
                { 
                    render: 'Error updating employee',
                    type: "error", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        })
    }

    return(
        <Modal
        toggle={toggle}
        isOpen={isOpen}>
            <ModalHeader
            toggle={toggle}
            >
                {employeeInfo.firstName} {employeeInfo.lastName}
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
            <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor='firstName'>First Name</Label>
                                <Input
                                id="firstName"
                                name="firstName"
                                value={employeeInfo.firstName}
                                onChange={(e) => {
                                    setEmployeeInfo({
                                        ...employeeInfo,
                                        firstName: e.target.value
                                    })
                                }}
                                required
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor='lastName'>Last Name</Label>
                                <Input
                                id="lastName"
                                name="lastName"
                                value={employeeInfo.lastName}
                                onChange={(e) => {
                                    setEmployeeInfo({
                                        ...employeeInfo,
                                        lastName: e.target.value
                                    })
                                }}
                                required
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor='payrollNumber'>Payroll Number</Label>
                                <Input
                                id="payrollNumber"
                                name="payrollNumber"
                                disabled={true}
                                value={employeeInfo.payrollNumber}
                                required
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor='assignedCompanyId'>Assigned Company ID</Label>
                                <Input
                                id="assignedCompanyId"
                                name="assignedCompanyId"
                                value={employeeInfo?.assignedCompanyId || ''}
                                onChange={(e) => {
                                    setEmployeeInfo({
                                        ...employeeInfo,
                                        assignedCompanyId: e.target.value
                                    })
                                }}
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor='assignedCompanyAlias'>Assigned Company Alias</Label>
                                <Input
                                id="assignedCompanyAlias"
                                name="assignedCompanyAlias"
                                value={employeeInfo?.assignedCompanyAlias || ''}
                                onChange={(e) => {
                                    setEmployeeInfo({
                                        ...employeeInfo,
                                        assignedCompanyAlias: e.target.value
                                    })
                                }}
                                >
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                        <FormGroup>
                            <Label for="phone">Phone</Label>
                            {/* <div style={{ width: '200px' }}> */}
                            <MaskedInput
                                name="phone"
                                id="phone"
                                value={employeeInfo.phone}
                                onChange={(e) => {
                                    setEmployeeInfo({
                                    ...employeeInfo,
                                    phone: e.target.value
                                })}}
                                mask={[
                                    '(',
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ')',
                                    ' ',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                ]}
                                placeholder="(xxx) xxxx-xxxx"
                                className={`form-control`}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor='email'>Email</Label>
                            <Input
                            id="email"
                            type='email'
                            name="email"
                            value={employeeInfo.email}
                            onChange={(e) => {
                                setEmployeeInfo({
                                    ...employeeInfo,
                                    email: e.target.value
                                })
                            }}
                            >
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                    <Row>
                    <Col>
                        <FormGroup>
                            <Label for="department">Department:</Label>
                                <Select
                                    id="departmentCode"
                                    name="departmentCode"
                                    classNamePrefix="react-select"
                                    onChange={(selection) => {
                                            setEmployeeInfo({
                                                ...employeeInfo,
                                                departmentCode: selection.value
                                            })
                                        }}
                                    value={!employeeInfo.departmentCode ? '' : departments.map(dep => ({ value: dep.departmentCode, label: dep.name })).find(dep => dep.value === employeeInfo.departmentCode)}
                                    options={departments.map(dep => ({ value: dep.departmentCode, label: dep.name }))}
                                >
                                </Select>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary">
                    Submit
                </Button>{' '}
            </ModalFooter>
            </Form>
        </Modal>
    )
}

export default EmployeeEditModal;