import React from 'react';
import { Row, Col } from 'reactstrap';
import MissedPunchIns from './reusable-components/MissingPunchIns';

const Dashboard = () => {
    return(
        <Row className='mt-3'>
            <Col lg={8}>
                <MissedPunchIns />
            </Col>
        </Row>
    )
}

export default Dashboard;