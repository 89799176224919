import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, CardHeader, UncontrolledCollapse, Table, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup} from 'reactstrap';
import { toast } from "react-toastify";
import { ESApi } from "../api/ESApi";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import Select from "react-select";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import monitorIcon from '@iconify/icons-mdi/monitor';
import cellphoneIcon from '@iconify/icons-mdi/cellphone';
import clockPlusOutline from '@iconify/icons-mdi/clock-plus-outline';
import fileCheckOutline from '@iconify/icons-mdi/file-check-outline';
import { add, sub } from "date-fns";
import informationIcon from '@iconify/icons-mdi/information';
import DownloadCSV from "../utility-components/DownloadCsv";

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

const MegapayReport = (props) => {

    const [payrollPeriod, setPayrollPeriod] = useState(null);

    const [payrollPeriodDate, setPayrollPeriodDate] = useState(null);

    const [payrollPeriodYear, setPayrollPeriodYear] = useState(null);

    const [departmentCode, setDepartmentCode] = useState(null);

    const [payrollPeriodSelectOptions, setPayrollPeriodSelectOptions] = useState([]);

    const [loading, setLoading] = useState(true);

    const [reportData, setReportData] = useState([]);

    const [csvData, setCsvData] = useState(null);

    const [employeeData, setEmployeeData] = useState(null)

    const paginationOptions = {
        defaultSorted: [{ dataField: 'payrollNumber', order: '' }],
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: '50',
                value: 50,
            },
            {
                text: 'All',
                value: reportData?.length
            }
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
        {
            dataField: 'payrollNumber',
            text: 'Payroll Number',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'name',
            text: 'Employee Name',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'departments',
            text: 'Department',
            sort: false,
            style: { fontWeight: '800', verticalAlign: 'middle' },
            formatter: (cell, row) => cell.length > 1 ? cell.length + ' departments' : cell[0].departmentCode
        },
        {
            dataField: '',
            text: '',
            style: { verticalAlign: 'middle' },
            sort: false,
            formatter: (cell, row) => (
                <Button className='link-button p-0' color="link" style={{ color: '#6c757d', fontSize: '26px' }}
                onClick={(e) => {
                    e.stopPropagation()
                    setEmployeeData(row)
                }}
                ><Icon icon={informationIcon} /></Button>
            )
        }
    ];
    

    const expandRow = {
        renderer: row => (

                <Row className="justify-content-center">
                {Object.keys(row)
                .filter(val => !['payrollNumber', 'name', 'departments'].includes(val))
                .map(val =>
                    (<Col sm={'auto'}>
                        <Card style={{ width: '200px' }}>
                            <CardHeader className="bg-primary text-white text-center">
                                <b>{val.toUpperCase()}</b>
                            </CardHeader>
                            <CardBody className="text-center">
                                <b>{row[val]?.toFixed(2)}</b>
                            </CardBody>
                        </Card>
                    </Col>)
                )}
                </Row>

        ),
        showExpandColumn: true,
        onlyOneExpanding: false,
        expandByColumnOnly: false,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
        },
        expandColumnRenderer: ({ expanded }) => {
            return expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
        },
    };

    useEffect(() => {
        ESApi.get('/payroll/currentPayrollPeriod')
        .then(({data}) => {
            setPayrollPeriod(data.payrollPeriod)
            setPayrollPeriodDate(data.currentPayrollPeriodDate)
            setPayrollPeriodYear(new Date(data.currentPayrollPeriodDate).getFullYear())
            let options = [{
                label: `${data.payrollPeriod} - ${new Date(data.currentPayrollPeriodDate).toLocaleDateString()}`,
                year: new Date(data.currentPayrollPeriodDate).getFullYear(),
                value: data.payrollPeriod
            }];

            // let nextWeeksDate = add(new Date(data.currentPayrollPeriodDate), {
            //     weeks: 1
            // })

            // options.push({
            //     label: `${data.payrollPeriod + 1 > 52 ? (data.payrollPeriod + 1) - 52 : data.payrollPeriod + 1} - ${nextWeeksDate.toLocaleDateString()}`,
            //     year: nextWeeksDate.getFullYear(),
            //     value: data.payrollPeriod + 1 > 52 ? (data.payrollPeriod + 1) - 52 : data.payrollPeriod + 1
            // })

            for(let i = 1; i < 30; i++){

                let thisWeeksDate = sub(new Date(data.currentPayrollPeriodDate), {
                    weeks: i
                })

                options.unshift({
                    label: `${data.payrollPeriod - i < 1 ? 52 + (data.payrollPeriod - i) : data.payrollPeriod - i} - ${thisWeeksDate.toLocaleDateString()}`,
                    year: thisWeeksDate.getFullYear(),
                    value: data.payrollPeriod - i < 1 ? 52 + (data.payrollPeriod - i) : data.payrollPeriod - i
                })
            }

            setPayrollPeriodSelectOptions(options)
        })
    }, [])

    const csvHeaders = [
        { label: "Employee ID", key: "employeeId" },
        { label: "Job Code", key: "jobCode" },
        { label: "E/D Code", key: "edCode" },
        { label: "Hours", key: "hrs" },
        { label: "Amounts", key: "amounts" }
    ];

    const generateCsvReportData = (data) => {
        let csvData = [];

        data.forEach(line => {
            Object.keys(line)
            .filter(val => !['payrollNumber', 'name', 'departments'].includes(val))
            .forEach(val => {
                let csvLine = {
                    employeeId: line.payrollNumber,
                    edCode: val?.toUpperCase(),
                    hrs: ["e02", "e03", "e04", "e07", "e06", "nysfpl"].includes(val) ? line[val] : '',
                    amounts: !["e02", "e03", "e04", "e07", "e06", "nysfpl"].includes(val) ? line[val] : '',
                }

                csvData.push(csvLine)
            })
        })

        return csvData
    }

    useEffect(() => {
        if(payrollPeriod && payrollPeriodYear){
        setLoading(true)
        ESApi.get('/reports/payroll', { params: { payrollPeriod, payrollYear: payrollPeriodYear } })
        .then(({data}) => {
            setReportData(data)
            setCsvData(generateCsvReportData(data))
            setLoading(false)
        })}
    }, [payrollPeriod, payrollPeriodYear])

    return(
        <>{loading ?
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Report...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
        :
        <>
        <Row className="justify-content-end align-items-center my-2" style={{  }}>
            <Col className="align-self-start">
                <h3>Megapay Report - Total Employees: {reportData?.length}</h3>
            </Col>
            <Col md={'auto'}>
                <DownloadCSV 
                    data={csvData} 
                    headers={csvHeaders}
                    disabled={!csvData}
                    className="btn btn-primary"
                    filename={`megapay_report-${payrollPeriod}-${new Date(payrollPeriodDate).toLocaleDateString()}`}
                />
            </Col>
            <Col md={'auto'}>
                <FormGroup className="d-flex align-items-center mb-0">
                    <Label for="ed-codes" className="m-0 mr-1">Payroll Period:</Label>
                        <div style={{ minWidth: '300px' }}>
                        <Select
                            id="payroll-period"
                            classNamePrefix="react-select"
                            name="payroll-period"
                            isDisabled={loading}
                            onChange={(selection) => {
                                console.log({selection})
                                    setPayrollPeriodYear(selection.year)
                                    setPayrollPeriod(selection.value)
                                }}
                            value={payrollPeriodSelectOptions.find(option => option.value === payrollPeriod)}
                            options={payrollPeriodSelectOptions}
                        >
                        </Select>
                        </div>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
        <Card>
            <CardBody>
                <BootstrapTable
                    bootstrap4
                    keyField="payrollNumber"
                    condensed
                    bordered={false}
                    data={reportData}
                    columns={columns}
                    pagination={paginationFactory(paginationOptions)}
                    expandRow={expandRow}
                    wrapperClasses="table-responsive batch-report-table"
                    defaultSorted={
                        [
                            {
                                dataField: 'payrollNumber',
                                order: 'asc'
                            }
                        ]
                    }
                />
            </CardBody>
        </Card>
        </Col>
        </Row>
        <EmployeePayrollDetailModal data={employeeData} toggle={() => setEmployeeData(null)}/>
        </>
        }
        </>
    )
}

const EmployeePayrollDetailModal = ({ data, toggle }) => {
    console.log({data})
    return(
        <>
        {data ?
        <Modal
        isOpen={data}
        toggle={toggle}
        >
            <ModalHeader toggle={toggle}>
                {data.name}
            </ModalHeader>
            <ModalBody>
                {data.departments.map(department => (
                    <> 
                    <Card>
                        <CardBody>
                    <Row className="justify-content-center">
                        <h4 className="text-center">{department.departmentCode}<br /><br />
                        {new Date(department.startPayrollWeekDate).toLocaleDateString()} - {new Date(department.endPayrollWeekDate).toLocaleDateString()}
                        </h4>
                    </Row>
                    <hr />
                    <Row className="justify-content-center">
                    {Object.keys(department)
                    .filter(val => !['payrollNumber', 'name', 'departments', 'departmentCode', 'endPayrollWeekDate', 'startPayrollWeekDate'].includes(val))
                    .map(val =>
                        (<Col sm={'auto'}>
                            <Card style={{ width: '150px' }}>
                                <CardHeader className="bg-primary text-white text-center">
                                    <b>{val.toUpperCase()}</b><br />
                                </CardHeader>
                                <CardBody className="text-center">
                                    <b>{department[val].toFixed(2)}</b>
                                </CardBody>
                            </Card>
                        </Col>)
                    )}
                    </Row>
                    </CardBody>
                    </Card>
                    </>
                ))}
            </ModalBody>
        </Modal>
        :
        ''
        }
        </>
    )
}

export default MegapayReport;