// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';

// Themes
import './assets/scss/Saas.scss';
import AddPunchInModal from './custom-modals/AddPunchInModal';
import AddEdCodeModal from './custom-modals/AddEdCodeModal';
import { DepartmentProvider } from './contexts/DepartmentContext';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
    render() {
        return (
            <BrowserRouter 
            // basename='/srx/faci'
            >
                <UserProvider>
                    <DepartmentProvider>
                        <Routes></Routes>
                        <ToastContainer
                            position="bottom-right"
                            theme="light"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            icon
                            draggable
                            pauseOnHover
                        />
                    </DepartmentProvider>
                </UserProvider>  
            </BrowserRouter>)
    }
}

export default App;
