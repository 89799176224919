import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, CardHeader, UncontrolledCollapse, Table, Input } from 'reactstrap';
import { toast } from "react-toastify";
import { ESApi } from "../api/ESApi";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import monitorIcon from '@iconify/icons-mdi/monitor';
import cellphoneIcon from '@iconify/icons-mdi/cellphone';
import clockPlusOutline from '@iconify/icons-mdi/clock-plus-outline';
import fileCheckOutline from '@iconify/icons-mdi/file-check-outline';

function departmentTotalHrs(department){
    let accumulator = 0;
    department.employees.forEach(({ punchIns }) => punchIns.forEach(punchIn => {
        console.log(parseFloat(punchIn.hrs))
        if(!!parseFloat(punchIn.hrs))
            accumulator += parseFloat(punchIn.hrs)
    }))
    return accumulator
}

const columns = [
    {
        dataField: 'departmentCode',
        text: 'Department Code',
        sort: true,
    },
    {
        dataField: 'name',
        text: 'Department Name',
        sort: true,
    },
    {
        dataField: '',
        text: 'Total Hrs',
        sort: true,
        style: { fontWeight: '800' },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') return departmentTotalHrs(rowA) < departmentTotalHrs(rowB) ? -1 : 1;
            else return departmentTotalHrs(rowB) > departmentTotalHrs(rowA) ? 1 : -1;
          },
        formatter: (cell, row) => departmentTotalHrs(row).toFixed(2)
    }
];

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

const paginationOptions = {
    paginationSize: 10,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
    ], // A numeric array is also available. the purpose of above example is custom the text
};

const BatchReport = () => {

    const start = startOfWeek(new Date(), {weekStartsOn: 1});
    const end = endOfWeek(new Date(), {weekStartsOn: 1}); 
    
    const [dateRange, setDateRange] = useState([start, end]);

    const [searchTerm, setSearchTerm] = useState('');

    function format(inputDate) {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${month}/${date}/${year}`;
    }

    function reFormat(inputDate){
        let splitDate = inputDate.split('/');
        let month = splitDate[0];
        let day = splitDate[1];
        let year = splitDate[2];

        return `${year}-${month}-${day}`
    }

    const [reportData, setReportData] = useState([]);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dateRange.length === 2){
            setLoading(true)
            ESApi.get(`/departments/punch-ins?startDate=${reFormat(format(dateRange[0]))}&endDate=${reFormat(format(dateRange[1]))}`)
            .then(res => {
                let dataWithId = res.data.map((data, i) => {
                    return {
                        ...data,
                        id: i + 1
                    }
                })
                setReportData(dataWithId)
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
            })
        }
    }, [dateRange])

    const expandRow = {
        renderer: row => (
            <>
            {row.employees.map((employee, i) => (
                <Card className="mb-0 mx-4">
                    <CardHeader id={"employee" + i} className="batch-report-employee-card-header d-flex align-items-center">
                        <span className="mr-auto"><b>{employee.firstName} {employee.lastName} - {employee.payrollNumber}</b></span>
                        <span className="float-right">Total Hrs: <b>{employee.punchIns.reduce((total, punchIn) => total + Number(punchIn.hrs), 0).toFixed(2)}</b></span>
                        <span className="float-right ml-3">
                            <i className="mdi mdi-chevron-down font-18 accordion-arrow"></i>
                        </span>
                    </CardHeader>
                    <UncontrolledCollapse toggler={"employee" + i}>
                    <CardBody>
                        <Table className="mb-0" striped bordered size="sm">
                            <thead>
                                <tr style={{ whiteSpace:'nowrap' }}>
                                    <th>Date</th>
                                    <th>Time In</th>
                                    <th>Time Out</th>
                                    <th>Hours</th>
                                    <th>Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.punchIns.map((punchIn, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{format(new Date(punchIn.date.replace('-', '/')))}</td>
                                            <td>{new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                                            <td>{punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : "---------"}</td>
                                            <td>{punchIn.hrs}</td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                            {punchIn.punchAddedFrom === 'custom_entry' &&
                                            <div title={'System Entry'}>
                                            <Icon icon={monitorIcon} height="24" width="24"/>
                                            </div>
                                            }
                                            {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('app')) &&
                                            <div title={punchIn.inPunchMethod}>
                                            <Icon icon={cellphoneIcon} height="24" width="24" title={punchIn.inPunchMethod}/>
                                            </div>
                                            }
                                            {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('clock')) &&
                                            <div title={punchIn.inPunchMethod}>
                                            <Icon icon={clockPlusOutline} height="24" width="24"/>
                                            </div>
                                            }
                                            {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('manual')) &&
                                            <div title={punchIn.inPunchMethod}>
                                            <Icon icon={fileCheckOutline} height="24" width="24" title={punchIn.inPunchMethod}/>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </CardBody>
                    </UncontrolledCollapse>
                </Card>
            ))}
            </>
        ),
        showExpandColumn: true,
        onlyOneExpanding: false,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
        },
        expandColumnRenderer: ({ expanded }) => {
            return expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
        },
    };

    function searchResults(arr){
        return arr.filter(department => department.name && department.name.toLowerCase().includes(searchTerm.toLowerCase()) || department.departmentCode.toLowerCase().includes(searchTerm.toLowerCase()))
    }

    return(
        <>{loading ?
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Report...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
        :
        <>
        <Row className="justify-content-end align-items-center my-2" style={{  }}>
            <Col>
                <h3>Batch Report</h3>
            </Col>
            <Col md={'auto'}>
            <FormGroup className='mb-0 ml-3 d-flex'>
                <Label className='text-white d-flex align-items-center mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>Date Range : </Label>
                <InputGroup style={{ width: '350px' }}>
                        <FlatPicker
                            className='form-control rounded-left bg-white'
                            value={dateRange}
                            onChange={(date) => setDateRange(date)}
                            options={
                                {
                                    // maxDate: 'today',
                                    mode: "range",
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                }
                            }
                            placeholder="Choose date..."
                        ></FlatPicker>
                        <InputGroupAddon
                            addonType="append"
                        >
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={calendarRange} width="25px" />
                            </span>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col>
        <Card>
            <CardBody>
                <Row>
                    <Col md={'auto'}>
                    <FormGroup style={{ width: '300px' }}>
                        <Input 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search by department..."
                        ></Input>
                    </FormGroup>
                    </Col>
                </Row>
                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    bordered={false}
                    data={searchResults(reportData)}
                    columns={columns}
                    pagination={paginationFactory(paginationOptions)}
                    expandRow={expandRow}
                    wrapperClasses="table-responsive batch-report-table"
                    defaultSorted={
                        [
                            {
                                dataField: 'name',
                                order: 'asc'
                            }
                        ]
                    }
                />
            </CardBody>
        </Card>
        </Col>
        </Row>
        </>
        }
        </>
    )
}

export default BatchReport;