// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

type FileUploaderProps = {
    onFileUpload?: PropTypes.func,
    showPreview?: boolean,
};

type FileUploaderState = {
    selectedFiles: Array<PropTypes.object>,
};

class CustomFileUploader extends Component<FileUploaderProps, FileUploaderState> {
    static defaultProps = {
        showPreview: true,
    };

    constructor(props: FileUploaderProps) {
        super(props);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);

        this.state = {
            selectedFiles: [],
        };
    }

    /**
     * Handled the accepted files and shows the preview
     */
    handleAcceptedFiles = (files: Array<PropTypes.object>) => {
        if(this.state.selectedFiles.length)
            return toast.error('Only 1 file can be uploaded')
        var allFiles = files;

        let invalidFiles = false;

        if (this.props.fileFilter) {
            files.forEach(file => {
                
                if(this.props.fileFilter.indexOf(file.type) < 0)
                    invalidFiles = true;

            })
        }

        if(invalidFiles)
            return toast.error('Only files of the following type/s are accepted:' + this.props.fileFilter.join(', '))

        if (this.props.showPreview) {
            files.map(file =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: this.formatBytes(file.size),
                })
            );

            allFiles = this.state.selectedFiles;
            allFiles.push(...files);
            this.setState({ selectedFiles: allFiles });
        }

        if (this.props.onFileUpload) this.props.onFileUpload(allFiles);
    };

    /**
     * Deletes Accepted Files
     */

    deleteAcceptedFile = (file) => {
        this.setState({
            selectedFiles: this.state.selectedFiles.filter(selectedFile => selectedFile.name !== file.name)
        })
        this.props.setPendingFiles(this.props.pendingFiles.filter(selectedFile => selectedFile.name !== file.name))
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    render() {
        return (
            <React.Fragment>
                <Dropzone {...this.props} 
                onDrop={acceptedFiles => {
                    if(acceptedFiles.length > 1)
                        return toast.error('Only 1 file at a time')
                    this.handleAcceptedFiles(acceptedFiles)
                    }}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <i className="h1 text-muted dripicons-cloud-upload"></i>
                                <h5>Drop files here or click to upload.</h5>
                            </div>
                        </div>
                    )}
                </Dropzone>

                {this.props.showPreview && (
                    <div className="dropzone-previews mt-3" id="file-previews">
                        {this.state.selectedFiles.map((f, i) => {
                            return (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + '-file'}>
                                    <div className="p-2">
                                        <Row className="align-items-center">
                                            {f.preview && (
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                </Col>
                                            )}
                                            {!f.preview && (
                                                <Col className="col-auto">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title bg-primary rounded">
                                                            {f.type.split('/')[0]}
                                                        </span>
                                                    </div>
                                                </Col>
                                            )}
                                            <Col className="pl-0">
                                                <Link to="#" className="text-muted font-weight-bold">
                                                    {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='position-absolute' style={{ right: '-5px', top: '-10px' }}>
                                        <Button color="link" className='link-button' style={{ fontSize: '25px', color: '#6c757d' }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.deleteAcceptedFile(f)
                                        }}
                                        >
                                            <b>x</b>
                                        </Button>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default CustomFileUploader;
