import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, Toast, ToastHeader, ToastBody, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import FileUploader from '../components/FileUploader';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ESApi } from '../api/ESApi';
import { toast } from 'react-toastify';
import Parser from "papaparse";
import difference from 'lodash.difference';
import differenceBy from 'lodash.differenceby';
import CustomFileUploader from '../utility-components/CustomFileUploader';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-mdi/close';
import { startOfWeek, endOfWeek, isMonday, nextTuesday, nextWednesday, nextThursday, nextFriday, nextSaturday, nextSunday, format, 
    previousSunday, previousMonday, previousTuesday, previousWednesday, previousThursday, previousFriday } from 'date-fns';
import DownloadCSV from '../utility-components/DownloadCsv';

const Uploads = props => {

    let uploadType = props.match.params.uploadType;

    useEffect(() => {

        let validUploadTypes = ['custom-punch-in-template', 'churchill-punch-ins', 'liebers-punch-ins', 'fingercheck-punch-ins', 'quickway-punch-ins', 'fabuwood-punch-ins']
        if(validUploadTypes.indexOf(uploadType) < 0){
            props.history.replace('/')
        }

    }, [])

    const pageNames = [
        { value: 'custom-punch-in-template', label: 'Custom Template Upload Page' },
        { value: 'churchill-punch-ins', label: 'Churchill Punch Ins' },
        { value: 'liebers-punch-ins', label: 'Liebers Punch Ins' },
        { value: 'fingercheck-punch-ins', label: 'Fingercheck Punch In Template' },
        { value: 'quickway-punch-ins', label: 'Quickway Punch In Template' },
        { value: 'fabuwood-punch-ins', label: 'Fabuwood Punch In Template' },
    ]

    const [resultToast, setResultToast] = useState({
        isOpen: false,
        results: null,
        uploadCsvTemplateName: ''
    })

    const [pendingFiles, setPendingFiles] = useState([])

    const [updatedKey, setUpdatedKey] = useState(0);

    const handleValidSubmitToServer = () => {
        if(!pendingFiles.length) return

        let uploadFilePromises = [];

        pendingFiles.forEach((file) => console.log(JSON.parse(JSON.stringify(file))))

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded")

        let pendingFilesArray = [];   
        
        let multiFileObject = new FormData();

        for(let pendingFile of pendingFiles){
            multiFileObject.append('files', pendingFile);
        }

        ESApi.post('/upload/csv', multiFileObject)
        .then(res => {
            setPendingFiles([])
            setUpdatedKey(prevKey => prevKey + 1)
            toast.success('Files successfully uploaded')
        })
        .catch(err => {
            toast.error('Error: ', err?.message)
        })

    }

    const handleLocalParse = async () => {
        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 
        
        let multiFileObject = new FormData();

        for(let pendingFile of pendingFiles){
            Parser.parse(pendingFile, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    console.log({results})
                }
            })
        }
    } 

    const handlePunchInTemplateParse = () => {

        function normalizeHeader(header){
                return header.toLowerCase().replaceAll(' ', '-')
        }

        let requiredHeaders = [
            'time-in',
            'employee-name', 
            'lunch', 
            'megapay-id',  
            'date', 
            'time-out',
            'department-code'
        ]

        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 

        for(let pendingFile of pendingFiles){
            let parsingCSVToast = toast.loading('Parsing CSV...', {
                theme: 'light',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            Parser.parse(pendingFile, {
                header: true,
                skipEmptyLines: true,
                dynamicTyping: true,
                transformHeader: normalizeHeader,
                complete: (results) => {
                    let difference = differenceBy(requiredHeaders, results.meta.fields, normalizeHeader);
                    let additionalHeaders = differenceBy(results.meta.fields, requiredHeaders, normalizeHeader);
                    console.log({additionalHeaders})
                    if(difference.length > 0){
                        return toast.update(parsingCSVToast, 
                            { 
                                render: 'The uploaded CSV is missing the following fields: ' + JSON.stringify(difference),
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    }
                    if(additionalHeaders.length > 0){
                        return toast.update(parsingCSVToast, 
                            { 
                                render: 'The uploaded CSV has invalid fields: ' + JSON.stringify(additionalHeaders),
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    }

                    let processedResults = []

                    for(let result of results.data){
                        const {
                            date,
                            'department-code': departmentCode,
                            'employee-name': name,
                            lunch,
                            'megapay-id': payrollNumber,
                            'time-in': timeIn,
                            'time-out': timeOut
                        } = result;

                        let punchInObject = {
                            date,
                            departmentCode,
                            name,
                            punchInType: lunch ? 'lunch' : 'reg',
                            payrollNumber,
                            timeIn,
                            timeOut
                        }

                        processedResults.push(punchInObject)
                    }
                    toast.update(parsingCSVToast, 
                        { 
                            render: `Successfully parsed ${results.data.length} new punch-ins`,
                            type: "success", 
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    console.log({results})

                    let uploadingDataToast = toast.loading(`Uploading ${results.data.length} punch-ins...`, {
                        theme: 'light',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    ESApi.post('/upload/punch-in-template', processedResults)
                    .then(res => {
                        toast.dismiss(uploadingDataToast)
                        setUpdatedKey(prevKey => prevKey + 1)
                        setResultToast({
                            isOpen: true,
                            results: res.data,
                            uploadCsvTemplateName: 'punch-in-template'
                        })
                        setPendingFiles([])
                    })
                    .catch(err => {
                        toast.update(uploadingDataToast, 
                            { 
                                render: `Error uploading ${results.data.length} new punch-ins`,
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })
                }
            })
        }
    }

    const handleQuickwayPunchInParse = () => {

        function normalizeHeader(header){
                return header.toLowerCase().replaceAll(' ', '-')
        }

        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 

        for(let pendingFile of pendingFiles){
            let parsingCSVToast = toast.loading('Parsing CSV...', {
                theme: 'light',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            Parser.parse(pendingFile, {
                header: true,
                dynamicTyping: true,
                complete: (results) => {

                    let processedResults = []

                    let punchInObject = {}
                    // this function is specific for this upload
                    function fixDate(date){
                        let splitDate = date.split('/')
                        splitDate[2] = '20' + splitDate[2]
                        return splitDate.join('/')
                    }

                    for(let result of results.data){
                        const {
                            Date: date,
                            Name: name,
                            PIN: assignedCompanyId,
                            I: timeIn,
                            O: timeOut,
                            Punch: punchTime
                        } = result;

                        if(timeIn){
                            punchInObject = {
                                date: fixDate(date),
                                name,
                                departmentCode: 'Quickway Imports',
                                assignedCompanyId,
                                timeIn: punchTime,
                                timeOut,
                                punchInType: 'reg'
                            }
                        } else {
                            punchInObject.timeOut = punchTime
                        }

                        if(timeOut)
                            processedResults.push(punchInObject)
                    }
                    toast.update(parsingCSVToast, 
                        { 
                            render: `Successfully parsed ${results.data.length} new punch-ins`,
                            type: "success", 
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    console.log({processedResults})
                    
                    let uploadingDataToast = toast.loading(`Uploading ${results.data.length} punch-ins...`, {
                        theme: 'light',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    ESApi.post('/upload/punch-in-template', processedResults)
                    .then(res => {
                        toast.dismiss(uploadingDataToast)
                        setUpdatedKey(prevKey => prevKey + 1)
                        setResultToast({
                            isOpen: true,
                            results: res.data,
                            uploadCsvTemplateName: 'quickway-imports'
                        })
                        setPendingFiles([])
                    })
                    .catch(err => {
                        toast.update(uploadingDataToast, 
                            { 
                                render: `Error uploading ${results.data.length} new punch-ins`,
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })
                }
            })
        }
    }

    const handleFabuwoodPunchInParse = () => {

        // returns true if entire row is empty
        const isEmptyRow = (row) => Object.values(row).every(x => !x); 

        function normalizeHeader(header){
                return header.toLowerCase().replaceAll(' ', '-')
        }

        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 

        for(let pendingFile of pendingFiles){
            let parsingCSVToast = toast.loading('Parsing CSV...', {
                theme: 'light',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            Parser.parse(pendingFile, {
                dynamicTyping: true,
                complete: ({data}) => {
                    
                    

                    let processedResults = []

                    let weekEndingDate = data[0][0].trim().split(' ')[1] + '/' + new Date().getFullYear()

                    // format specifically for this function formats to mm/DD/yyyy
                    function getFormattedDate(date) {
                        var year = date.getFullYear();
                      
                        var month = (1 + date.getMonth()).toString();
                      
                        var day = date.getDate().toString();
                        day = day.length > 1 ? day : '0' + day;
                        
                        return month + '/' + day + '/' + year;
                      }

                    for (let i = 1;  i < data.length; i++){
                        
                        // check if row is empty
                        if(isEmptyRow(data[i]))
                            continue

                        console.log({i}, {data: data[i]})
                        let variable = data[i][0]
                        
                        if(data[i][0].toLowerCase() === "Total hours".toLowerCase() || data[i][0].toLowerCase() === "Temp Elite".toLowerCase() || data[i][0].toLowerCase() === "Name".toLowerCase())
                            continue

                        let punches = [
                            {
                                date: getFormattedDate(previousSunday(new Date(weekEndingDate))),
                                departmentCode: 'Fabuwood',
                                name: data[i][0],
                                assignedCompanyId: null,
                                assignedCompanyAlias: data[i][0],
                                timeIn: data[i][2],
                                timeOut: data[i][3],
                                punchInType: 'reg'
                            },
                            {
                                date: getFormattedDate(previousMonday(new Date(weekEndingDate))),
                                departmentCode: 'Fabuwood',
                                name: data[i][0],
                                assignedCompanyId: null,
                                assignedCompanyAlias: data[i][0],
                                timeIn: data[i][5],
                                timeOut: data[i][6],
                                punchInType: 'reg'
                            },
                            {
                                date: getFormattedDate(previousTuesday(new Date(weekEndingDate))),
                                departmentCode: 'Fabuwood',
                                name: data[i][0],
                                assignedCompanyId: null,
                                assignedCompanyAlias: data[i][0],
                                timeIn: data[i][8],
                                timeOut: data[i][9],
                                punchInType: 'reg'
                            },
                            {
                                date: getFormattedDate(previousWednesday(new Date(weekEndingDate))),
                                departmentCode: 'Fabuwood',
                                name: data[i][0],
                                assignedCompanyId: null,
                                assignedCompanyAlias: data[i][0],
                                timeIn: data[i][11],
                                timeOut: data[i][12],
                                punchInType: 'reg'
                            },
                            {
                                date: getFormattedDate(previousThursday(new Date(weekEndingDate))),
                                departmentCode: 'Fabuwood',
                                name: data[i][0],
                                assignedCompanyId: null,
                                assignedCompanyAlias: data[i][0],
                                timeIn: data[i][14],
                                timeOut: data[i][15],
                                punchInType: 'reg'
                            },
                            {
                                date: getFormattedDate(previousFriday(new Date(weekEndingDate))),
                                departmentCode: 'Fabuwood',
                                name: data[i][0],
                                assignedCompanyId: null,
                                assignedCompanyAlias: data[i][0],
                                timeIn: data[i][17],
                                timeOut: data[i][18],
                                punchInType: 'reg'
                            }
                        ]

                        processedResults.push(...punches)
                        
                    }

                    toast.update(parsingCSVToast, 
                        { 
                            render: `Successfully parsed ${processedResults.length} new punch-ins`,
                            type: "success", 
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    console.log({processedResults})
                    
                    let uploadingDataToast = toast.loading(`Uploading ${processedResults.length} punch-ins...`, {
                        theme: 'light',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    // filter out punches without punch in and punch out times
                    processedResults = processedResults.filter(punchIn => !punchIn.timeIn.includes('-') && !punchIn.timeIn.includes('-'))

                    ESApi.post('/upload/punch-in-template', processedResults)
                    .then(res => {
                        toast.dismiss(uploadingDataToast)
                        setUpdatedKey(prevKey => prevKey + 1)
                        setResultToast({
                            isOpen: true,
                            results: res.data,
                            uploadCsvTemplateName: 'fabuwood'
                        })
                        setPendingFiles([])
                    })
                    .catch(err => {
                        toast.update(uploadingDataToast, 
                            { 
                                render: `Error uploading ${processedResults.length} new punch-ins`,
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })
                }
            })
        }
    }

    const handleLiebersPunchInsParse = () => {

        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 

        for(let pendingFile of pendingFiles){
            let parsingCSVToast = toast.loading('Parsing CSV...', {
                theme: 'light',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            Parser.parse(pendingFile, {
                dynamicTyping: true,
                complete: (results) => {

                    toast.update(parsingCSVToast, 
                        { 
                            render: `Successfully parsed ${results.data.length} new punch-ins`,
                            type: "success", 
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    
                    console.log({results})

                    let manipulatedArr = []

                    let currentUserId;

                    let currentUserName;

                    for(let result of results.data){
                        if(result.indexOf('Weekly Attendance Listing') > -1){
                            continue
                        }
                        else if(result.filter(x => x)[0] ? result.filter(x => x)[0].startsWith('Page') : false){
                            continue
                        } else if(result.findIndex(val => typeof val === 'string' ? val.startsWith('User') : false) > -1){
                            currentUserId = result.find(val => val ? val.startsWith('User') : false).split(':')[1].trim();
                            currentUserName = result.find(val => val ? val.startsWith('Name') : false).split(':')[1].trim();
                            continue
                        } else if(result.indexOf('Day Type') > -1){
                            continue
                        } else if(result.length < 2){
                            continue
                            
                        } else if(result[0] ? result[0].startsWith('Printed ') : false){
                            continue
                            
                        } else if(result.filter(x => x)[0] ? result.filter(x => x)[0].startsWith('Total') : false){
                            continue
                            
                        } else {
                            let punchInWorkObjectMorning = {
                                date: result[1].split(' ')[0].trim().replaceAll('-', '/'),
                                punchInType: 'reg',
                                timeIn: result[7],
                                timeOut: result[10],
                                name: currentUserName,
                                assignedCompanyId: currentUserId,
                                departmentCode: 'Liebers'
                            }

                            let punchInWorkObjectAfternoon = {
                                date: result[1].split(' ')[0].trim().replaceAll('-', '/'),
                                punchInType: 'reg',
                                timeIn: result[12],
                                timeOut: result[14],
                                name: currentUserName,
                                assignedCompanyId: currentUserId,
                                departmentCode: 'Liebers'
                            }
                            manipulatedArr.push(punchInWorkObjectMorning)
                            manipulatedArr.push(punchInWorkObjectAfternoon)
                        }
                        
                    }

                    console.log({ manipulatedArr })

                    let uploadingDataToast = toast.loading(`Uploading ${manipulatedArr.length} punch-ins...`, {
                        theme: 'light',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    // filter out punches without timein or timeout
                    manipulatedArr = manipulatedArr.filter(punchIn => punchIn.timeIn && punchIn.timeOut)

                    ESApi.post('/upload/punch-in-template', manipulatedArr)
                    .then(res => {
                        toast.dismiss(uploadingDataToast)
                        setUpdatedKey(prevKey => prevKey + 1)
                        setResultToast({
                            isOpen: true,
                            results: res.data,
                            uploadCsvTemplateName: 'liebers'
                        })
                        setPendingFiles([])
                    })
                    .catch(err => {
                        toast.update(uploadingDataToast, 
                            { 
                                render: `Error uploading ${manipulatedArr.length} new punch-ins`,
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })
                }
            })
        }
    }

    const handleChurchillPunchInsParse = () => {

        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 

        for(let pendingFile of pendingFiles){
            let parsingCSVToast = toast.loading('Parsing CSV...', {
                theme: 'light',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            Parser.parse(pendingFile, {
                skipEmptyLines: true,
                dynamicTyping: true,
                complete: (results) => {
                    
                    toast.update(parsingCSVToast, 
                        { 
                            render: `Successfully parsed ${results.data.length} new punch-ins`,
                            type: "success", 
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    console.log({results})

                    const processedResultsArr = []

                    for(let result of results.data){
                        const {
                            0: payrollWeekUnparsed,
                            2: employeeNameUnparsed,
                            4: assignedCompanyIdUnparsed,
                            11: punchInDayUnparsed,
                            12: punchInTypeUnparsed,
                            13: timeInUnparsed,
                            14: timeOutUnparsed,
                            15: punchInHrsUnparsed,
                            16: dailyHrsUnparsed, // total hrs for day
                            18: weeklyHrsUnparsed
                        } = result;
    
                        let punchInObject = {};
    
                        // determine payroll week start
    
                        function isNumeric(value) {
                                return /^-?\d+$/.test(value);
                            }
    
                        function returnPayrollStartAndEndDates(unparsedString){
                            let res = unparsedString.split('').findIndex(isNumeric)
                            
                            let datesUnparsed = unparsedString.slice(unparsedString.split('').findIndex(isNumeric))
                            
                            let datesArr = datesUnparsed.trim().split('-').map(date => date.trim())
                            
                            return{
                                startDate: datesArr[0],
                                endDate: datesArr[1]
                            }
                        }

                        let payrollWeekStartDay = returnPayrollStartAndEndDates(payrollWeekUnparsed).startDate;
    
                        function returnDateForWeekday(day){
                            switch(day.toLowerCase()){
                                case 'monday':
                                    return new Date(payrollWeekStartDay)
                                case 'tuesday':
                                    return nextTuesday(new Date(payrollWeekStartDay))
                                case 'wednesday':
                                    return nextWednesday(new Date(payrollWeekStartDay))
                                case 'thursday':
                                    return nextThursday(new Date(payrollWeekStartDay))
                                case 'friday':
                                    return nextFriday(new Date(payrollWeekStartDay))
                                case 'saturday':
                                    return nextSaturday(new Date(payrollWeekStartDay))
                                case 'sunday':
                                    return nextSunday(new Date(payrollWeekStartDay))
                                default:
                                    return  new Date(payrollWeekStartDay)  
                            }
                        }

                        let weekday = returnDateForWeekday(punchInDayUnparsed)

                        let usaFormat = format(weekday, 'L/dd/yyyy')

                        punchInObject.name = employeeNameUnparsed;

                        punchInObject.date = usaFormat;

                        punchInObject.assignedCompanyId = assignedCompanyIdUnparsed;

                        punchInObject.punchInType = punchInTypeUnparsed.trim().toLowerCase() === 'work' ? 'reg' : 'lunch';

                        punchInObject.hrs = Math.abs(punchInHrsUnparsed);

                        punchInObject.timeIn = timeInUnparsed?.trim()

                        punchInObject.timeOut = timeOutUnparsed?.trim()

                        punchInObject.departmentCode = 'Churchill';

                        processedResultsArr.push(punchInObject)
                    }

                    let uploadingDataToast = toast.loading(`Uploading ${results.data.length} punch-ins...`, {
                        theme: 'light',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    ESApi.post('/upload/punch-in-template', processedResultsArr)
                    .then(res => {
                        toast.dismiss(uploadingDataToast)
                        setUpdatedKey(prevKey => prevKey + 1)
                        setResultToast({
                            isOpen: true,
                            results: res.data,
                            uploadCsvTemplateName: 'churchill'
                        })
                        setPendingFiles([])
                    })
                    .catch(err => {
                        toast.update(uploadingDataToast, 
                            { 
                                render: `Error uploading ${results.data.length} new punch-ins`,
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })

                    // let uploadingDataToast = toast.loading(`Uploading ${results.data.length} punch-ins...`, {
                    //     theme: 'light',
                    //     autoClose: 3000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    // });

                    // ESApi.post('/upload/punch-in-template', results.data)
                    // .then(res => {
                    //     toast.dismiss(uploadingDataToast)
                    //     setUpdatedKey(prevKey => prevKey + 1)
                    //     setResultToast({
                    //         isOpen: true,
                    //         results: res.data
                    //     })
                    //     setPendingFiles([])
                    // })
                    // .catch(err => {
                    //     toast.update(uploadingDataToast, 
                    //         { 
                    //             render: `Error uploading ${results.data.length} new punch-ins`,
                    //             type: "error", 
                    //             isLoading: false,
                    //             autoClose: 3000,
                    //             hideProgressBar: null,
                    //             closeOnClick: null,
                    //         });
                    // })
                }
            })
        }
    }

    const handleFingerCheckPunchInsParse = () => {

        if(!pendingFiles.length) return

        if(pendingFiles.findIndex(file => file.type !== "text/csv") >= 0)
            return toast.error("Only csv's can be uploaded") 

        for(let pendingFile of pendingFiles){
            let parsingCSVToast = toast.loading('Parsing CSV...', {
                theme: 'light',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            debugger
            Parser.parse(pendingFile, {
                dynamicTyping: true,
                complete: (results) => {

                    toast.update(parsingCSVToast, 
                        { 
                            render: `Successfully parsed ${results.data.length} new punch-ins`,
                            type: "success", 
                            isLoading: false,
                            autoClose: 3000,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    
                    console.log({results})

                    let assignedCompanyId = '';

                    let employeeName = '';

                    let department = '';

                    const processedResults = []

                    for(let result of results.data){
                        if(result.indexOf("Employee#:") > -1){
                            debugger
                            employeeName = result[1]
                            assignedCompanyId = result[5]
                            department = result[3]
                        }
                        else if((result.findIndex(val => typeof val === 'string' ? val.startsWith('Time Worked') : false) > -1) 
                        &&
                        (result.findIndex(val => typeof val === 'string' ? val.startsWith('Paid Hours') : false) > -1)){
                            debugger
                            continue
                        }
                        else if((result.indexOf("Date") > -1) && (result.indexOf("Date Worked") > -1)){
                            debugger
                            continue
                        }
                        else if((result.findIndex(val => typeof val === 'string' ? val.startsWith("**Totals**") : false) > -1) 
                        &&
                        (result.findIndex(val => typeof val === 'string' ? val.startsWith('Regular') : false) > -1)){
                            debugger
                            continue
                        }
                        else if((result.findIndex(val => typeof val === 'string' ? val.includes("Time") : false) > -1) 
                        &&
                        (result.findIndex(val => typeof val === 'string' ? val.startsWith("Paid Totals") : false) > -1)){
                            debugger
                            continue
                        }
                        else if((result.findIndex(val => typeof val === 'string' ? val.includes("**Company Totals**") : false) > -1) 
                        &&
                        (result.findIndex(val => typeof val === 'string' ? val.includes("Regular") : false) > -1)){
                            debugger
                            continue
                        }
                        else if(result.indexOf("Weekly Time Card") > -1){
                            debugger
                            continue
                        } 
                        else if(result.filter(x => x).length < 1){
                            debugger
                            continue
                        }
                        else {
                            let punchInObject = {
                                date: result[0]?.trim(),
                                name: employeeName,
                                timeIn: result[2]?.trim(),
                                timeOut: result[3]?.trim(),
                                departmentCode: result[4]?.trim(),
                                punchInType: result[11]?.trim() === 'Regular' ? 'reg' : 'lunch',
                                assignedCompanyId: assignedCompanyId
                            }

                            processedResults.push(punchInObject)
                        }
                    }

                    console.log({processedResults})


                    let uploadingDataToast = toast.loading(`Uploading ${processedResults.length} punch-ins...`, {
                        theme: 'light',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    ESApi.post('/upload/punch-in-template', processedResults)
                    .then(res => {
                        toast.dismiss(uploadingDataToast)
                        setUpdatedKey(prevKey => prevKey + 1)
                        setResultToast({
                            isOpen: true,
                            results: res.data,
                            uploadCsvTemplateName: 'fingercheck'
                        })
                        setPendingFiles([])
                    })
                    .catch(err => {
                        toast.update(uploadingDataToast, 
                            { 
                                render: `Error uploading ${processedResults.length} new punch-ins`,
                                type: "error", 
                                isLoading: false,
                                autoClose: 3000,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })
                }
            })
        }
    }

    return(
        <>
        <Row>
            <Col>
                <h2>Upload {pageNames.find(pageName => pageName.value === uploadType)?.label}</h2>
            </Col>
        </Row>
        <Row className='position-relative'>
            <Col>
                <Card>
                    <CardBody>
                    <AvForm onValidSubmit={() => {
                        if(uploadType === 'custom-punch-in-template')
                            handlePunchInTemplateParse()
                        if(uploadType === 'churchill-punch-ins')
                            handleChurchillPunchInsParse()
                        if(uploadType === 'liebers-punch-ins')
                            handleLiebersPunchInsParse()
                        if(uploadType === 'fingercheck-punch-ins')
                            handleFingerCheckPunchInsParse()
                        if(uploadType === 'quickway-punch-ins')
                            handleQuickwayPunchInParse()
                        if(uploadType === 'fabuwood-punch-ins')
                            handleFabuwoodPunchInParse()
                        }}>
                        <FormGroup>
                            <CustomFileUploader
                            // this key is for rerendering purposes
                                uploadType={uploadType}
                                key={updatedKey}
                                onFileUpload={files => {
                                    console.log(files);
                                    setPendingFiles(files)
                                }}
                                fileFilter={['text/csv']}
                                pendingFiles={pendingFiles}
                                setPendingFiles={setPendingFiles}
                            />
                        </FormGroup>
                        <FormGroup className='d-flex justify-content-end'>
                            <Button color="success"
                            disabled={!pendingFiles.length}
                            >Upload</Button>
                        </FormGroup>
                    </AvForm>
                    </CardBody>
                </Card>
            </Col>
            {resultToast.isOpen && <CustomToast 
                results={resultToast.results}
                uploadCsvTemplateName={resultToast.uploadCsvTemplateName}
                toggle={() => setResultToast({
                    isOpen: false,
                    results: null,
                    uploadCsvTemplateName: ''
                })}
            />}
        </Row>
        </>
    )
}

// add leading zero to invalid times
function formatTime(_time){
    if(!_time)
        return null
    var time = _time.toUpperCase()
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    let isAMPM = time.match(/\s(.*)$/);
    if(!isAMPM)
        return new Date('1970-01-01T' + _time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    return new Date('1970-01-01T' + sHours + ":" + sMinutes).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
}

const CustomToast = ({ isOpen, results, toggle, uploadCsvTemplateName }) => (
<Modal
size='lg'
isOpen={true}
toggle={toggle}
>
    <ModalHeader toggle={toggle}>
        Upload results
    </ModalHeader>
    <ModalBody className='text-center'>
    <h5>Added Punch-Ins: <b>{results?.rowsAdded}</b></h5>
            <h5>Updated Punch-Ins: <b>{results?.rowsUpdated}</b></h5>
            <h5>Ignored Punch-Ins: <b>{results?.rowsIgnored}</b></h5>
            <div>
            <DownloadCSV 
                data={results?.rejectionReasons.map(reason => (
                    {
                        name: reason.row.name,
                        date: reason.row.date,
                        timeIn: reason.row.timeIn,
                        timeOut: reason.row.timeOut,
                        reason: reason.reason
                    }
                ))} 
                headers={[
                    { label: "Employee Name", key: "name" },
                    { label: "Date", key: "date" },
                    { label: "Time In", key: "timeIn" },
                    { label: "Time Out", key: "timeOut" },
                    { label: "Reason", key: "reason" }
                ]}
                className="btn btn-primary"
                filename={`${uploadCsvTemplateName}-upload-csv-${new Date().toLocaleDateString()}`}
                title="Download Rejection Reason CSV"
            />
            </div>
            <div className='mt-2'>
            <DownloadCSV 
                data={results?.rejectionReasons.filter(({error}) => error).map(reason => (
                    {
                        payrollNumber: reason.row.payrollNumber || null,
                        name: reason.row.name,
                        date: reason.row.date,
                        departmentCode: reason.row.departmentCode,
                        timeIn: formatTime(reason.row.timeIn),
                        timeOut: formatTime(reason.row.timeOut),
                        lunch: reason.row.punchInType === 'reg' ? false : true
                    }
                ))} 
                headers={[
                    { label: "Megapay Id", key: "payrollNumber" },
                    { label: "Date", key: "date" },
                    { label: "Department Code", key: "departmentCode" },
                    { label: "Employee Name", key: 'name' },
                    { label: "Time In", key: "timeIn" },
                    { label: "Time Out", key: "timeOut" },
                    { label: "Lunch", key: "lunch" }
                ]}
                className="btn btn-primary"
                filename={`${uploadCsvTemplateName}-prefilled-custom-template-${new Date().toLocaleDateString()}`}
                title="Download Prefilled Custom Template"
            />
            </div>
            <Table className="mb-0 mt-3" striped size="sm">
                <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                    <tr>
                        <th>Employee Name</th>
                        <th>Date</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {results?.rejectionReasons.map((reason, index) => {
                            const { date, name, timeIn, timeOut } = reason.row;
                            return (
                                <tr key={index}>
                                    <td>{name}</td>
                                    <td>{date}</td>
                                    <td>{timeIn}</td>
                                    <td>{timeOut}</td>
                                    <td>{reason.reason}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
    </ModalBody>
</Modal>
)

export default Uploads;