import React from 'react';
import { Card, CardBody, CardHeader, CardFooter, Row, Col } from 'reactstrap';
import { Icon } from '@iconify/react';
import fileDocumentEditOutline from '@iconify/icons-mdi/file-document-edit-outline';
import { Link } from 'react-router-dom';

const Reports = () => {
    return(
        <>
        <Row style={{ height: '30px' }}>

        </Row>
        <Row>
            <Col xs={'auto'}>
                <Link to={'/reports/batch-report'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={fileDocumentEditOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Batch Report</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
            <Col xs={'auto'}>
                <Link to={'/reports/megapay-report'}>
                    <Card style={{ width: '300px' }}>
                        <CardBody className='d-flex justify-content-center align-items-center'>
                            <Icon icon={fileDocumentEditOutline} color="#D6E0EC" width="150" height="150" />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-center' style={{ overflow: 'hidden', maxHeight: '84px', minHeight: '84px' }}>
                            <h4>Megapay Report</h4>
                        </CardFooter>
                    </Card>
                </Link>
            </Col>
        </Row>
        </>
    )
} 

export default Reports;