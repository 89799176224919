import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';         
import { ESApi } from '../api/ESApi';
import { toast } from 'react-toastify';
import Select from 'react-select';


const CreateSettingsModal = ({ isOpen, toggle, department}) => {

    const [settings, setSettings] = useState({
    
        punch_rounding: department.punch_rounding,
        overtime_settings: department.overtime_settings,
        overtimeLimitAmount: department.overtimeLimitAmount,

        overtimeStartDeduction: department.overtimeStartDeduction,
        overtimeDeductionAmount: department.overtimeDeductionAmount,
        overtimeDeductionLimit: department.overtimeDeductionLimit

    });

    useEffect(() => {
        ESApi.get('/departments/' + department.departmentCode).then((res) => setSettings(res.data));
    }, []);


    let punch_rounding = [
        {
            value: '5_minute_rounding_with_a_3_minute_breakpoint',
            label: '5 minute rounding with a 3 minute breakpoint',
        },
        {
            value: '6_minute_rounding_with_a_3-minute_breakpoint',
            label: '6 minute rounding with a 3 minute breakpoint',
        },
        {
            value: '10_minute_rounding_with_a_5_minute_breakpoint',
            label: '10 minute rounding with a 5 minute breakpoint',
        },
        {
            value: '15_minute_rounding_with_an_8-minute_breakpoint',
            label: '15 minute rounding with an 8 minute breakpoint',
        },
        {
            value: '30_minute_rounding_with_a_15_minute_breakpoint',
            label: '30 minute rounding with a 15 minute breakpoint',
        },
        {
            value: '60_minute_rounding_with_a_30_minute_breakpoint',
            label: '60 minute rounding with a 30 minute breakpoint',
        },
    ];

    let overtime_settings = [
        { value: 'overtimeLimit', label: 'Overtime Limit' },
        { value: 'dynamicOvertimeRules', label: 'Dynamic Overtime Rules' },
    ];

    function saveSettings() {
        ESApi.put('/departments/settings/' + department.departmentCode, settings)
            .then((res) => {
                toast.success('Settings Saved');
                toggle();
            })
            .catch((err) => toast.error('Error saving Settings'));            
        }

         
    return (
        <Modal toggle={toggle} isOpen={isOpen}>
            <ModalHeader toggle={toggle}>Department Settings: {settings.name}</ModalHeader>
            <Form>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="punch_rounding">Rounding</Label>
                                <Select
                                    id="punch_rounding"
                                    name="punch_rounding"
                                    classNamePrefix="react-select"
                                    onChange={(selection) => {
                                        setSettings({
                                            ...settings,
                                            punch_rounding: selection.value,
                                        });
                                    }}
                                    value={punch_rounding.find((setting) => setting.value === settings.punch_rounding)}
                                    options={punch_rounding}></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="overtime_settings">Overtime Settings</Label>
                                <Select
                                    id="overtime_settings"
                                    name="overtime_settings"
                                    classNamePrefix="react-select"
                                    onChange={(selection) => {
                                        setSettings({
                                            ...settings,
                                            overtime_settings: selection.value,
                                        });
                                    }}
                                    value={overtime_settings.find(
                                        (setting) => setting.value === settings.overtime_settings
                                    )}
                                    options={overtime_settings}></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    {settings.overtime_settings === 'overtimeLimit' && (
                        <Row>
                            <Col sm={5}>
                                <FormGroup>
                                    <Label for="overtimeLimitAmount">Set Overtime Limit</Label>
                                    <Input
                                        type="number"
                                        step={1}
                                        value={settings.overtimeLimitAmount}
                                        onChange={(selection) => {
                                            setSettings({
                                                ...settings,
                                                overtimeLimitAmount: selection.target.value,
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    <Row className="justify-content-center justify-content-between">
                        {settings.overtime_settings === 'dynamicOvertimeRules' && (
                            // <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label>Start Deduction</Label>
                                    <Input
                                        type="number"
                                        step={1}
                                        value={settings.overtimeStartDeduction}
                                        onChange={(selection) => {
                                            setSettings({
                                                ...settings,
                                                overtimeStartDeduction: selection.target.value,
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            // </Row>
                        )}
                        {settings.overtime_settings === 'dynamicOvertimeRules' && (
                            // <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label>Deduction Amount/pd</Label>
                                    <Input
                                        type="number"
                                        step={0.25}
                                        value={settings.overtimeDeductionAmount}
                                        onChange={(selection) => {
                                            setSettings({
                                                ...settings,
                                                overtimeDeductionAmount: selection.target.value,
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            // </Row>
                        )}
                        {settings.overtime_settings === 'dynamicOvertimeRules' && (
                            // <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label>Deduction Limit</Label>
                                    <Input
                                        type="number"
                                        step={1}
                                        value={settings.overtimeDeductionLimit}
                                        onChange={(selection) => {
                                            setSettings({
                                                ...settings,
                                                overtimeDeductionLimit: selection.target.value,
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            // </Row>
                        )}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={saveSettings} color="primary">
                        Submit
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
}


export default CreateSettingsModal;