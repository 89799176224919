import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { ESApi } from '../api/ESApi';
import jwt_decode from "jwt-decode";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);

    const [apiToken, setApiToken] = useState(null);

    const [userLoading, setUserLoading] = useState(true);

    let history = useHistory();
    let location  = useLocation();

    useEffect(() => {

        let localStorageToken = localStorage.getItem('ELITE_STAFFING_TOKEN');

        if(localStorageToken !== undefined && localStorageToken !== null){
            axios.get(process.env.REACT_APP_ELITE_STAFFING_API_BASE_URL + '/auth/verifyToken', {
                headers: {
                    'Authorization': 'Bearer ' + localStorageToken
                }
            })
            .then(res => {
                ESApi.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageToken;
                setApiToken(localStorageToken);
                let decoded_token = jwt_decode(localStorageToken);
                setUser(decoded_token)
            })
            .catch(err => {
                ESApi.defaults.headers.common['Authorization'] = undefined;
                setApiToken(null);
                localStorage.removeItem('ELITE_STAFFING_TOKEN');
                // history.push('/account/login');
            })
            .finally(() => setUserLoading(false))
        } else {
            setUserLoading(false)
            setApiToken(null);
        }

    }, [])

    const loginUser = async (loginInfo) => {
        setUserLoading(true);

        try{

            const tokenRequest = await axios.post(process.env.REACT_APP_ELITE_STAFFING_API_BASE_URL + '/auth/login', loginInfo)
            
            ESApi.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRequest.data.token;
            localStorage.setItem('ELITE_STAFFING_TOKEN', tokenRequest.data.token)
            toast.success('User Logged In');
            setApiToken(tokenRequest.data.token);
            setUser(jwt_decode(tokenRequest.data.token));
            
        } catch(err){
            console.log(err)

            setApiToken(null);
            ESApi.defaults.headers.common['Authorization'] = undefined;
            toast.error('Error: Login failed');
        } finally {   
            setUserLoading(false)
        }
	}

    const logoutUser = () => {
        ESApi.defaults.headers.common['Authorization'] = undefined;
        localStorage.removeItem('ELITE_STAFFING_TOKEN');
        setApiToken(null)
        toast.success('User Logged Out');
        history.push('/account/login');
    }

    // const logoutUser = () => {
    //     localStorage.removeItem('OMNI_TOKEN');
    //     OmniApi.defaults.headers.common['token'] = undefined;
    //     XOmniApi.defaults.headers.common['token'] = undefined;
    //     toast.success("User logged out", {
    //         theme: 'colored',
    //         position: "top-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    //     setUser('');
    //     history.push('/account/login');
    //     // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // } 


    
    return (
        <UserContext.Provider value={{ user, setUser, userLoading, loginUser, logoutUser, apiToken, setApiToken }}>
            {props.children}
        </UserContext.Provider>
    );
}

