import React, { createContext, useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import axios from 'axios';

export const DepartmentContext = createContext();

export const DepartmentProvider = (props) => {

    const { apiToken, setApiToken } = useContext(UserContext);

    const history = useHistory();

    const [departments, setDepartments] = useState([]);
    
    useEffect(() => {
        if(apiToken){
            axios.get(process.env.REACT_APP_ELITE_STAFFING_API_BASE_URL + '/departments',{
                headers: {
                    'Authorization': 'Bearer ' + apiToken
                }
            })
            .then(res => {
                setDepartments(res.data)
            })
            .catch(err => {
                if(err.response?.status === 401){
                    localStorage.removeItem('ELITE_STAFFING_TOKEN');
                    setApiToken(null);
                    history.replace('/account/login')
                }
            })
        } else {
            setDepartments([])
        }
    }, [apiToken])
    
    return (
        <DepartmentContext.Provider value={{ departments }}>
            {props.children}
        </DepartmentContext.Provider>
    );
}