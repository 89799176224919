import React from 'react';
import { CSVLink } from "react-csv";

// headers = [
//   { label: "First Name", key: "firstname" },
//   { label: "Last Name", key: "lastname" },
//   { label: "Email", key: "email" }
// ];

// data = [
//   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
//   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
//   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
// ];

const DownloadCSV = ({ data, headers, disabled, filename, title = "Download CSV" }) => 
<CSVLink 
data={data} 
headers={headers}
disabled={disabled}
filename={filename}
className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
>
  {title}
</CSVLink>;

export default DownloadCSV;
