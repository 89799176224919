import React, { useContext, useEffect, useState } from 'react';
import { ESApi } from '../api/ESApi';
import { Modal, ModalHeader, ModalBody, Table, Row, Col, Card, CardHeader, CardBody, Label, InputGroup, InputGroupAddon, FormGroup, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import monitorIcon from '@iconify/icons-mdi/monitor';
import cellphoneIcon from '@iconify/icons-mdi/cellphone';
import clockPlusOutline from '@iconify/icons-mdi/clock-plus-outline';
import fileCheckOutline from '@iconify/icons-mdi/file-check-outline';
import { DepartmentContext } from '../contexts/DepartmentContext';
import accountHardHat from '@iconify/icons-mdi/account-hard-hat';
import foodForkDrink from '@iconify/icons-mdi/food-fork-drink';

const EmployeeModal = ({ payrollNumber }) => {

    const { departments } = useContext(DepartmentContext);

    console.log({payrollNumber})

    const start = startOfWeek(new Date(), {weekStartsOn: 1});
    const end = endOfWeek(new Date(), {weekStartsOn: 1}); 

    function format(inputDate) {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${month}/${date}/${year}`;
    }

    function reFormat(inputDate){
        let splitDate = inputDate.split('/');
        let month = splitDate[0];
        let day = splitDate[1];
        let year = splitDate[2];

        return `${year}-${month}-${day}`
    }

    let history = useHistory()

    const [employee, setEmployee] = useState(null);

    const [employeeDepartments, setEmployeeDepartments] = useState([])

    const [payrollPeriod, setPayrollPeriod] = useState(null);

    const [payrollPeriodDate, setPayrollPeriodDate] = useState(null);

    const [payrollPeriodYear, setPayrollPeriodYear] = useState(null);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(payrollNumber){
        ESApi.get(`/employees/${payrollNumber}/departments`)
        .then(res => {
            setEmployeeDepartments(res.data)
        })}
    }, [payrollNumber])

    useEffect(() => {
        ESApi.get('/payroll/currentPayrollPeriod')
        .then(({data}) => {
            setPayrollPeriod(data.payrollPeriod)
            setPayrollPeriodDate(data.currentPayrollPeriodDate)
            setPayrollPeriodYear(new Date(data.currentPayrollPeriodDate).getFullYear())
        })
    }, [])

    useEffect(() => {
        
        if(payrollPeriod && payrollPeriodYear && payrollNumber){
            setLoading(true)
            ESApi.get(`/employees/${payrollNumber}`, { params: { payrollPeriod, payrollPeriodYear } })
            .then(res => {
                setEmployee(res.data)})
            .catch(err => console.error(err))
            .finally(setLoading(false))
        }

    }, [payrollPeriod, payrollPeriodYear, payrollNumber])

    function toggle(){
        setTimeout(() => setEmployee(null), 250)
        history.push('/employees')
    }

    return(
        <Modal
            isOpen={payrollNumber}
            toggle={toggle}
            size='lg'
            >
            <ModalHeader toggle={toggle} className="d-flex flex-row"><span>{employee ? employee.firstName + ' ' + employee.lastName : ''}</span>
            <Button size='sm' className='ml-2'
            onClick={() => history.replace('/employees/' + payrollNumber)}
            >Go to detail</Button></ModalHeader>
            <ModalBody>
                {employee ?
                <>
                    <Row className='justify-content-end mb-1'>
                        <Col md={'auto'} style={{ fontSize: '16px' }}>
                            <p>Payroll Period Date: <b>{new Date(payrollPeriodDate).toLocaleDateString()}</b></p>
                            <p>Payroll Period: <b>{payrollPeriod}</b> Payroll Year: <b>{payrollPeriodYear}</b></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardBody className='bg-white'>
                                <div>
                                        <p>Payroll Number:<br /><b>{employee.payrollNumber}</b></p>
                                        <p>First Name:<br /><b>{employee.firstName}</b></p>
                                        <p>Last Name:<br /><b>{employee.lastName}</b></p>
                                        <p>Phone Number:<br /><b>{employee.phone}</b></p>
                                        {/* <p>Department:<br /><b>{employee.departments[0]?.departmentObject.name}</b></p> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card>
                            <CardHeader className='d-flex align-items-center'><h4>Departments</h4> <span className='ml-1'>- Past 4 Months</span></CardHeader>
                            <Table className="mb-0" striped>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Code</th>
                                        <th>Punch In Hrs</th>
                                        <th>Ed Codes Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeDepartments.map((department, index) => {
                                        const { departmentCode, punchInSum, edCodeSum } = department;
                                        return (
                                            <tr key={index}>
                                                <td>{departments.find(dep => dep.departmentCode === departmentCode)?.name}</td>
                                                <td>{departmentCode}</td>
                                                <td>{punchInSum}</td>
                                                <td>{edCodeSum}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            </Card>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                        <Card>
                        {/* <FormGroup className='mb-0 ml-3 d-flex'>
                        <Label className='text-white d-flex align-items-center mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>Date Range : </Label>
                        <InputGroup style={{ width: '350px' }}>
                                <FlatPicker
                                    className='form-control rounded-left bg-white'
                                    // value={date}
                                    // onChange={date => setDate(date)}
                                    options={
                                        {
                                            maxDate: 'today',
                                            mode: "range",
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d",
                                        }
                                    }
                                    placeholder="Choose date..."
                                ></FlatPicker>
                                <InputGroupAddon
                                    addonType="append"
                                >
                                    <span className="input-group-text py-0 px-1">
                                        <Icon icon={calendarRange} width="25px" />
                                    </span>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup> */}
                            <CardHeader><h4>Punch Ins</h4></CardHeader>
                            <SimpleBar style={{ maxHeight: '300px' }}>
                                <Table className="mb-0" striped>
                                <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                    <tr style={{ whiteSpace:'nowrap' }}>
                                        <th>Department Name</th>
                                        <th>Date</th>
                                        <th>Time In</th>
                                        <th>Time Out</th>
                                        <th>Hours</th>
                                        <th>Type</th>
                                        <th>Method</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employee.punchIns.map((punchIn, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ verticalAlign: 'middle' }}>{departments.find(dep => dep.departmentCode === punchIn.departmentCode)?.name}</td>
                                                <td>{new Date(punchIn.date).toLocaleDateString()}</td>
                                                <td>{new Date('1970-01-01T' + punchIn.timeIn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                                                <td>{punchIn.timeOut ? new Date('1970-01-01T' + punchIn.timeOut).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : "---------"}</td>
                                                <td>{punchIn.hrs}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchInType === 'reg' &&
                                                <div title={'Regular'}>
                                                <Icon icon={accountHardHat} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'lunch' &&
                                                <div title={'Lunch'}>
                                                <Icon icon={foodForkDrink} height="24" width="24"/>
                                                </div>
                                                }
                                                {punchIn.punchInType === 'break' &&
                                                <div title={'Break'}>
                                                    <b>Break</b>
                                                </div>
                                                }
                                            </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                {punchIn.punchAddedFrom === 'custom_entry' &&
                                                <div title={'System Entry'}>
                                                <Icon icon={monitorIcon} height="24" width="24"/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('app')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={cellphoneIcon} height="24" width="24" title={punchIn.inPunchMethod}/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('clock')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={clockPlusOutline} height="24" width="24"/>
                                                </div>
                                                }
                                                {(punchIn.punchAddedFrom === 'uattend' && punchIn.inPunchMethod.toLowerCase().includes('manual')) &&
                                                <div title={punchIn.inPunchMethod}>
                                                <Icon icon={fileCheckOutline} height="24" width="24" title={punchIn.inPunchMethod}/>
                                                </div>
                                                }
                                            </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            </SimpleBar>
                        </Card>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                    <Col>
                    <Card>
                        <CardHeader className='d-flex justify-content-between'><h4>ED Codes</h4>
                        </CardHeader>
                            <SimpleBar style={{ maxHeight: '300px' }}>
                                <Table className="mb-0" striped>
                                    <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                        <tr style={{ whiteSpace:'nowrap' }}>
                                            <th>Department Name</th>
                                            <th>Date</th>
                                            <th>Code</th>
                                            <th>Qty</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employee.edCodes.map((ed, index) => {
                                            const { description, quantityType } = ed.edCodeObject;
                                            return (
                                                <tr key={index}>
                                                    <td style={{ verticalAlign: 'middle' }}>{departments.find(dep => dep.departmentCode === ed.departmentCode)?.name}</td>
                                                    <td>{new Date(ed.createdAt).toLocaleDateString()}</td>
                                                    <td>{ed.edCode}</td>
                                                    <td>{quantityType === 'total_sum' ? <span><b>$</b> {ed.qty}</span> : <span>{ed.qty} <b>hrs</b></span>}</td>
                                                    <td>{description}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </SimpleBar>
                        </Card>
                    </Col>
                    </Row>
                </>
                    :
                    <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                    <div>
                    <h4>Loading Employee...</h4>
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        </div>
                    </div>
                }
            </ModalBody>
        </Modal>
    )

}

export default EmployeeModal;